import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../reducers'
import sessionSlice from '../../reducers/sessionSlice'
import socialSlice from '../../reducers/socialSlice'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import { selectCurrentBlaster, selectCurrentMatchSlug } from '../../selectors/session-selectors'
import {
  selectCurrentPlaylistSlug,
  selectCurrentTrackSlug,
} from '../../selectors/current-play-selectors'
import trackMixer from '../../services/TrackMixer'
import { PlaylistInfo, TrackInfo } from '../../types'
import { nextBackgroundImage } from '../viz-switch'
import clearSession from './clearSession'
import loadTrack from './loadTrack'
import keyBindings from '../../services/KeyBindings'

const selectTrack = createAsyncThunk<
  void,
  { playlistInfo: PlaylistInfo; trackInfo: TrackInfo; matchSlug: string },
  { state: RootState; dispatch: AppDispatch }
>(
  'load/selectedTrack',
  ({ playlistInfo, trackInfo, matchSlug: compoundMatchSlug }, { dispatch, getState }) => {
    trackMixer.suspend()
    const state = getState()
    const username = selectCurrentBlaster(state)[0].gamerId
    const currMatchSlug = selectCurrentMatchSlug(state)
    const [matchOwner] = compoundMatchSlug.split('/')
    const playlistSlug = selectCurrentPlaylistSlug(state)
    const trackSlug = selectCurrentTrackSlug(state) // TODO: make this all one selector
    if (currMatchSlug && playlistSlug && trackSlug) {
      const trackPath = {
        username,
        matchSlug: currMatchSlug,
        playlistSlug,
        trackSlug,
      }
      dispatch(
        socialSlice.actions.toggleTrackExpanded({
          ...trackPath,
          isExpanded: false,
        })
      )
      dispatch(socialSlice.actions.clearActiveTrack(trackPath))
      dispatch(
        socialSlice.actions.toggleTrackExpanded({
          ...trackPath,
          isLeft: true,
          isExpanded: false,
        })
      )
      dispatch(
        socialSlice.actions.clearActiveTrack({
          ...trackPath,
          isLeft: true,
        })
      )
      // if (compoundMatchSlug !== currMatchSlug) {
      //   dispatch(matchDidSwitch(compoundMatchSlug)).then(() => {
      //     dispatch(switchActiveBlaster({ gamerIndex: 0, newBlasterSlug: defaultBlaster }))
      //   })
      // }
    }
    keyBindings.clearContainers() // e.g. TrackGalleryModal after selection!
    const { slug: trackSlugToLoad } = trackInfo
    dispatch(currentPlaySlice.actions.setTrackInfo(trackInfo))
    // TODO: clear playstatus, too?
    dispatch(currentPlaySlice.actions.setLocalAudioPath('none'))
    dispatch(currentPlaySlice.actions.setLocalImagePath('none'))
    dispatch(currentPlaySlice.actions.setLocalImageData(''))
    dispatch(nextBackgroundImage(true))

    return dispatch(clearSession({ matchSlug: compoundMatchSlug, playlistInfo, trackSlug })).then(
      () => {
        ga('send', 'event', 'song', 'select', trackSlugToLoad)
        dispatch(loadTrack({ slug: trackSlugToLoad, playlistOwner: matchOwner }))
          .then(() => {
            dispatch(sessionSlice.actions.setTrackError(''))
          })
          .catch((error: any) => {
            const trackError = `error loading track[${trackSlugToLoad}]: ${error.message}`
            dispatch(sessionSlice.actions.setTrackError(trackError))
            console.log(trackError)
          })
        trackMixer.resume()
      }
    )
  }
)

export default selectTrack
