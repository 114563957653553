import { DEFAULT_TARGET } from '../../constants/constants'
import getState from '../../reducers/'
import vizParametersSlice from '../../reducers/vizParametersSlice'
import { LyricVisitorBaseSimple } from '../../util/visitors/LyricVisitorBase'
import Util from '../../util/util'
import player from '../TrackMixer'
import {
  ControlMap,
  Line,
  ModelPart,
  Point,
  Section,
  Target,
  VisitorBaseProps,
  Word,
} from '../../types'

class VizVisitorBase extends LyricVisitorBaseSimple {
  _firstTarget: Target
  _currentTarget: Target
  _offset: Point
  _nudge: Point
  activeWordColor: string
  passedLinesColor: string
  activeLineColor: string
  aheadLinesColor: string
  controls: ControlMap
  controlElems: { [key: string]: HTMLElement }
  vizContainer: HTMLElement
  bgColor: string

  constructor(props: VisitorBaseProps) {
    super()
    this._firstTarget = DEFAULT_TARGET
    this._currentTarget = DEFAULT_TARGET
    this._offset = { x: 0, y: 0 }
    this._nudge = props.nudge ? props.nudge : { x: 0, y: 0 }
    this.bgColor = ''
    this.activeWordColor = props.activeWordColor ? props.activeWordColor : '#000000'
    this.passedLinesColor = props.passedLinesColor ? props.passedLinesColor : '#000000'
    this.activeLineColor = props.activeLineColor ? props.activeLineColor : '#000000'
    this.aheadLinesColor = props.aheadLinesColor ? props.aheadLinesColor : '#000000'
    const colorProps = {
      type: 'color',
      min: '000000',
      max: 'FFFFFF',
      step: 5,
      isAnimateRandom: true,
    }
    this.controls = {
      bgColor: {
        ...colorProps,
        label: 'Background',
        initialValue: '#ffffff',
      },
      activeLineColor: {
        ...colorProps,
        label: 'Active Line',
        initialValue: this.activeLineColor,
      },
      passedLinesColor: {
        ...colorProps,
        label: 'Lines Passed',
        initialValue: this.passedLinesColor,
      },
      aheadLinesColor: {
        ...colorProps,
        label: 'Lines Ahead',
        initialValue: this.aheadLinesColor,
      },
    }
    this.controlElems = {}
    this.vizContainer = player.interactionContainer
  }

  visitTrack(isStart: boolean) {}

  // get controlsToSave() {
  //   const result = Object.keys(this.controls).reduce(function (acc, key) {
  //     const control = this.controls[key];
  //     const controlToSave = { ...control };
  //     delete controlToSave.elem;
  //     return {
  //       ...acc,
  //       [key]: controlToSave,
  //     };
  //   }.bind(this), {});
  //
  //   return result;
  // }

  get offset() {
    return this._offset
  }
  set offset(offset) {
    this._offset.x = offset.x + this._nudge.x
    this._offset.y = offset.y + this._nudge.y
  }

  get currentTarget() {
    return this._currentTarget
  }
  set currentTarget(currentTarget) {
    this._currentTarget = currentTarget
  }
  vizWillUpdate(offset: Point) {
    this.offset = offset
    const {
      vizParams: {
        controls: { /* bgColor, */ activeLineColor, passedLinesColor, aheadLinesColor },
      },
    } = getState()
    // this.bgColor = bgColor.initialValue
    // this.vizContainer.style.backgroundColor = this.bgColor
    this.activeLineColor = activeLineColor.initialValue as string
    this.passedLinesColor = passedLinesColor.initialValue as string
    this.aheadLinesColor = aheadLinesColor.initialValue as string
  }

  visitSection(section: Section) {}

  visitLine(line: Line, section: Section) {}

  visitWord(word: Word, line: Line, section: Section) {
    if (player.isVizMode) {
      this.setWordColor(word, line, section)
    }
    if (word.elem) {
      word.elem.style.backgroundColor = this.bgColor
      word.elem.style.borderColor = this.bgColor
    }
  }

  // getRandomColor() {
  //     const letters = '0123456789ABCDEF';
  //     const color = '#';
  //     for (let i = 0; i < 6; i++) {
  //         color += letters[Math.floor(Math.random() * 16)];
  //     }
  //     return color;
  // }

  updateControlValue(controlKey: string, value: string | number) {
    player.dispatch(
      vizParametersSlice.actions.updateControlParameter({
        controlKey,
        paramKey: 'initialValue',
        value,
      })
    )
  }
  updateControlStep(controlKey: string, value: string | number) {
    player.dispatch(
      vizParametersSlice.actions.updateControlParameter({ controlKey, paramKey: 'step', value })
    )
  }

  tick(part?: ModelPart) {
    const state = getState()
    const { min, max, isAnimateEnabled } = state.vizParams.controls.bgColor
    if (!isAnimateEnabled) {
      return
    }
    const minColor = parseInt(min as string, 16)
    const maxColor = parseInt(max as string, 16)

    if (isNaN(minColor) || isNaN(maxColor)) {
      return
    }
    const color = '#' + Util.randomIntFromInterval(minColor, maxColor).toString(16)

    this.updateControlValue('bgColor', color)
  }

  setWordColor(word: Word, line: Line, section: Section) {
    const { sectionDiff, lineDiff, wordDiff } = this.getPositionRelativeToTarget({
      sectionIndex: section.index,
      lineIndex: line.index,
      wordIndex: word.index,
    })
    const isActiveSection = sectionDiff === 0
    const isActiveLine = isActiveSection && lineDiff === 0
    const isActiveWord = isActiveLine && wordDiff === 0
    const isAhead =
      (isActiveLine && wordDiff > 0) || (isActiveSection && lineDiff > 0) || sectionDiff > 0
    const wordColor = isActiveWord
      ? this.activeWordColor
      : isActiveLine
      ? this.activeLineColor
      : isAhead
      ? this.aheadLinesColor
      : this.passedLinesColor
    if (word.elem) {
      word.elem.style.color = wordColor
      word.elem.classList[isActiveWord ? 'add' : 'remove']('selected')
    }
  }

  getPositionRelativeToTarget({
    sectionIndex,
    lineIndex,
    wordIndex,
  }: {
    sectionIndex: number
    lineIndex: number
    wordIndex: number
  }) {
    const {
      sectionIndex: currSectionIndex,
      lineIndex: currLineIndex,
      wordIndex: currWordIndex,
    } = this.currentTarget

    const sectionDiff = sectionIndex - currSectionIndex
    const lineDiff = lineIndex - currLineIndex
    const wordDiff = wordIndex - currWordIndex
    return { sectionDiff, lineDiff, wordDiff }
  }
}

export default VizVisitorBase
