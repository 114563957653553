import React, { ReactNode } from 'react'
import { stopPropagation } from '../../util/key-handlers'

const DEFAULT_MAX_INPUT_LENGTH = 50
interface LabelBase {
  label: string
  value?: string | number | boolean
  className?: string
  id?: string
  isReadOnly?: boolean
  onBlur?: any
  onChange?: any
  onClick?: any
  onKeyDown?: any
  onRef?: any
  onFocus?: any
  maxLength?: number
  isDisableTabIndex?: boolean
}

interface NumberInputWithLabelType extends LabelBase {
  rightLabel?: string
  isAutoFocus?: boolean
  min?: number
  max?: number
  pattern?: string
}
interface TextInputWithLabelType extends LabelBase {
  rightLabel?: string
  isAutoFocus?: boolean
}

interface CheckboxWithLabelType extends LabelBase {
  disabled?: boolean
}

interface ImageWithLabelType extends LabelBase {
  src: string
  onImageRef?: any
}

interface DivWithLabelType extends LabelBase {
  children?: ReactNode
  rightLabel?: string
}

interface SelectWithLabelType extends LabelBase {
  itemArray: { value: string; name: string }[]
}

const NumberInputWithLabel = ({
  id,
  label,
  isReadOnly,
  onBlur,
  onChange,
  onClick,
  value,
  onRef,
  min,
  max,
}: NumberInputWithLabelType) => {
  return (
    <div onClick={onClick}>
      {label && <label htmlFor={id}>{label}: </label>}
      <input
        id={id}
        ref={onRef}
        className="numberInputWithLabel"
        type="number"
        readOnly={isReadOnly}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={stopPropagation}
        defaultValue={value as number}
        min={min}
        max={max}
      />
    </div>
  )
}

const TextInputWithLabel = ({
  id,
  label,
  isReadOnly,
  onBlur,
  onChange,
  onClick,
  value,
  maxLength = DEFAULT_MAX_INPUT_LENGTH,
  onRef,
}: TextInputWithLabelType) => {
  return (
    <div onClick={onClick}>
      {label && <label htmlFor={id}>{label}: </label>}
      <input
        id={id}
        ref={onRef}
        className="textInputWithLabel"
        type="text"
        readOnly={isReadOnly}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={stopPropagation}
        defaultValue={value as string}
        maxLength={maxLength}
      />
    </div>
  )
}

const CheckboxWithLabel = ({
  id,
  label,
  disabled: isDisabled,
  value,
  onChange,
}: CheckboxWithLabelType) => {
  return (
    <div className="labeledCheckbox">
      <input
        type="checkbox"
        id={id}
        defaultChecked={!!value}
        disabled={isDisabled}
        onChange={onChange}
      />
      <label htmlFor={id} className="handle">
        {label}
      </label>
    </div>
  )
}

const ImageWithLabel = ({ id, label, src, onImageRef, onClick }: ImageWithLabelType) => {
  return (
    <div>
      <label htmlFor={id}>{label}: </label>
      <img
        id={id}
        ref={onImageRef}
        className="imageWithLabel"
        alt={label}
        src={src}
        onClick={onClick}
      />
    </div>
  )
}

const DivWithLabel = ({ id, label, className, children }: DivWithLabelType) => {
  return (
    <div>
      <label htmlFor={id}>{label}: </label>
      <div id={id} className={className}>
        {children}
      </div>
    </div>
  )
}

const SelectWithLabel = ({
  id,
  label,
  itemArray,
  value: selectedItemValue,
  onRef,
  onChange,
  onKeyDown,
  isDisableTabIndex,
}: SelectWithLabelType) => {
  const tabIndex = isDisableTabIndex ? -1 : 0
  return (
    <div className="labeledSelect">
      {label && <label htmlFor={id}>{label}: </label>}
      <select
        id={id}
        defaultValue={selectedItemValue as string}
        onChange={onChange}
        onClick={(event) => {
          event.stopPropagation()
        }}
        onKeyDown={onKeyDown}
        ref={onRef}
        tabIndex={tabIndex}
      >
        {itemArray.map(({ value, name }) => (
          <option key={value} value={value}>
            {name !== undefined ? name : value}
          </option>
        ))}
      </select>
    </div>
  )
}
const ControlledTextInputWithLabel = ({
  id,
  label,
  isReadOnly,
  onBlur,
  onChange,
  value,
  onRef,
}: TextInputWithLabelType) => {
  return (
    <div>
      {label && <label htmlFor={id}>{label}: </label>}
      <input
        id={id}
        ref={onRef}
        className="textInputWithLabel"
        type="text"
        readOnly={isReadOnly}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={stopPropagation}
        value={value as string}
      />
    </div>
  )
}

export {
  CheckboxWithLabel,
  ControlledTextInputWithLabel,
  DivWithLabel,
  ImageWithLabel,
  SelectWithLabel,
  TextInputWithLabel,
  NumberInputWithLabel,
}
