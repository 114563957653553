import {
  faBackward,
  faForward,
  faPause,
  faPlay,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import cx from 'classnames'
import { debounce } from 'lodash'
import React, { createRef, useEffect, MouseEvent, ChangeEvent, useState } from 'react'

import doSave from '../../actions/save'
import { activateNextPane, togglePlay } from '../../actions/play-actions'
import loadNextTrack from '../../actions/track-loading/loadNextTrack'
import { BLAST_BUTTON_TITLE, Pane, PaneClasses } from '../../constants/constants'
import { selectIsFocus } from '../../reducers/focusSlice'
import modalsSlice from '../../reducers/modalsSlice'
import {
  selectCurrentTrackSlug,
  selectIsNeedSyncing,
  selectLastBlasts,
} from '../../selectors/current-play-selectors'
import { selectIsOptionKey, selectModeFlags } from '../../selectors/session-selectors'
import trackMixer from '../../services/TrackMixer'
import { isMobile as getIsMobile } from '../../util/track-utils'
import { BlastStatus, PlayActions, SettingsActions } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'

type Props = {
  playActions: PlayActions
  settingsActions: SettingsActions
}
const DEBOUNCE_TIME_MS = 100

const PlayControls = ({ playActions }: Props) => {
  const isMobile = getIsMobile()
  const dispatch = useAppDispatch()
  const { rewindToStart, rewindTo, clear, forget } = playActions
  const { isEditMode, isVizMode } = useAppSelector(selectModeFlags)
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.PLAY_CONTROLS))
  const isNeedSyncing = useAppSelector(selectIsNeedSyncing)
  const isTrackLoaded = !!useAppSelector(selectCurrentTrackSlug)
  const { trackDuration, isPlaying, clockStartAtSeconds, isScoreDirty } = useAppSelector(
    (state) => state.currentPlay
  )
  const lastBlasts = useAppSelector(selectLastBlasts)
  const defaultBlastStatus = (): BlastStatus => ({
    isPressed: false,
    isRight: false,
    blastRank: 'bad',
  })
  const [status, setStatus] = useState<BlastStatus[]>([defaultBlastStatus(), defaultBlastStatus()])
  useEffect(() => {
    const newStatus = [defaultBlastStatus(), defaultBlastStatus()]
    if (lastBlasts.length === 1) {
      const { isRight } = lastBlasts[0]
      newStatus[isRight ? 1 : 0] = lastBlasts[0]
    } else if (lastBlasts.length === 2) {
      newStatus[0] = lastBlasts[0]
      newStatus[1] = lastBlasts[1]
    }
    setStatus(newStatus)
  }, [lastBlasts])
  const doneCount = 0 //gamerStatus.length ? gamerStatus[0].doneCount : 0
  const hasTiming = doneCount > 0
  const isOptionKey = useAppSelector(selectIsOptionKey(0))
  const onBlastClick = ({
    event,
    isPressed,
    isLeft,
  }: {
    event: MouseEvent
    isPressed: boolean
    isLeft: boolean
  }) => {
    if (isPlaying && !isPressed) {
      trackMixer.defaultGamer.blast(isLeft)
    }
    // setIsButtonPressed({ isLeft, isPressed })
    event.preventDefault()
  }
  const onLeftBlastDown = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: true, isLeft: true })
  }
  const onLeftBlastUp = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: false, isLeft: true })
  }
  const onRightBlastDown = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: true, isLeft: false })
  }
  const onRightBlastUp = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: false, isLeft: false })
  }

  const onPlayClick = () => dispatch(togglePlay())
  const onRewindClick = () => rewindToStart()
  const onScrubberInput = React.useMemo(
    () =>
      debounce(
        (event: ChangeEvent<HTMLInputElement>) => {
          rewindTo(parseFloat(event.target.value))
        },
        DEBOUNCE_TIME_MS,
        { leading: true }
      ),
    [rewindTo]
  )
  useEffect(() => {
    return () => {
      onScrubberInput.cancel()
    }
  }, [onScrubberInput])
  const onClearClick = (event: MouseEvent) => (event.altKey ? forget() : clear())
  const onSaveClick = (event: MouseEvent) => {
    if (isEditMode && !event.altKey) {
      dispatch(modalsSlice.actions.toggleSyncModal(true))
    } else {
      dispatch(doSave({ isDownload: event.altKey, gamer: trackMixer.defaultGamer }))
    }
  }
  const onPrevClick = () => {
    dispatch(loadNextTrack({ isPrevious: true }))
  }
  const onNextClick = () => {
    dispatch(loadNextTrack({}))
  }

  const leftBlastButtonClassName = cx('blastButton', {
    hidden: isVizMode,
    blasting: status[0].isPressed,
    mobile: isMobile,
    left: true,
    [`${status[0].blastRank}1`]: true,
  })
  const rightBlastButtonClassName = cx('blastButton', {
    hidden: isVizMode,
    blasting: status[1].isPressed,
    mobile: isMobile,
    right: true,
    [`${status[1].blastRank}1`]: true,
  })
  const clearButtonClassName = cx({ dirty: hasTiming })
  const exportButtonClassName = cx({ dirty: isScoreDirty || isNeedSyncing, hidden: isMobile })
  const isExportButtonEnabled = isScoreDirty || isOptionKey || isEditMode
  const playText = isPlaying ? 'Pause' : 'Play'
  const playIcon = (
    <FontAwesomeIcon icon={(isPlaying ? faPause : faPlay) as IconProp} title={playText} />
  )
  // const clockText = '-:-:-';
  // const durationText = '-:-:-'; // TODO: support displaying this while track is loading
  const clockRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = clockRef.current
    if (elem) {
      trackMixer.clock = elem
    }
  })
  const scrubberRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = scrubberRef.current
    if (elem) {
      trackMixer.scrubber = elem
    }
  })
  const durationRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = durationRef.current
    if (elem) {
      trackMixer.duration = elem
    }
  })
  const className = cx(PaneClasses[Pane.PLAY_CONTROLS], { hasFocus: isCurrFocus })
  const onClick = () => {
    // console.log(`container: ${PaneClasses[Pane.PLAY_CONTROLS]}`)
    dispatch(activateNextPane({ pane: Pane.PLAY_CONTROLS }))
  }

  return (
    <div className={className} onClick={onClick}>
      <div className="playUi">
        <div className="mobileControls">
          <button
            className={leftBlastButtonClassName}
            onMouseDown={onLeftBlastDown}
            onMouseUp={onLeftBlastUp}
            title={BLAST_BUTTON_TITLE}
            tabIndex={-1}
          />
        </div>
        <div className="playControls">
          <div>
            <input ref={clockRef} type="text" className="timer" readOnly />
            <input
              ref={scrubberRef}
              className="scrubber"
              type="range"
              step="0.2"
              min="0"
              max={trackDuration}
              onChange={onScrubberInput}
              onInput={onScrubberInput}
              disabled={!isTrackLoaded}
            />
            <input ref={durationRef} type="text" className="timer" readOnly />
          </div>
          <div>
            <button onClick={onPrevClick} aria-label="Previous Track" disabled={!isTrackLoaded}>
              <FontAwesomeIcon icon={faBackward as IconProp} title="Previous Track" />
            </button>
            <button
              onClick={onRewindClick}
              aria-label="restart"
              disabled={!isPlaying && clockStartAtSeconds === 0}
            >
              <FontAwesomeIcon icon={faUndoAlt as IconProp} title="Rewind to Start" />
            </button>
            <button id="play" aria-label={playText} onClick={onPlayClick} disabled={!isTrackLoaded}>
              {playIcon}
            </button>
            <button onClick={onNextClick} aria-label="Next Track" disabled={!isTrackLoaded}>
              <FontAwesomeIcon icon={faForward as IconProp} title="Next Track" />
            </button>
            {!trackMixer.isVizMode && (
              <button
                className={exportButtonClassName}
                onClick={onSaveClick}
                disabled={!isExportButtonEnabled}
              >
                {isOptionKey ? 'Export' : isEditMode ? 'Sync' : 'Save'}
              </button>
            )}
            {!trackMixer.isVizMode && (
              <button className={clearButtonClassName} onClick={onClearClick}>
                {isOptionKey ? 'Forget' : 'Clear'}
              </button>
            )}
          </div>
        </div>
        <div className="mobileControls">
          <button
            className={rightBlastButtonClassName}
            onMouseDown={onRightBlastDown}
            onMouseUp={onRightBlastUp}
            title={BLAST_BUTTON_TITLE}
            tabIndex={-1}
          />
        </div>
      </div>
    </div>
  )
}

export default PlayControls
