import { createAsyncThunk } from '@reduxjs/toolkit'

import { defaultMatchInfo, userStatuses } from '../constants/constants'
import blasterPeersSlice from '../reducers/blasterPeersSlice'
import matchStatusSlice from '../reducers/matchStatusSlice'
import sessionSlice from '../reducers/sessionSlice'
import { selectCurrentUsername } from '../selectors/session-selectors'
import realtime from '../services/RealtimeService'
import { isMobile } from '../util/track-utils'
import Util from '../util/util'
import { initLocalAuthoringFromStorage } from './authoring/local-storage-actions'
import { initOrUpdateCurrentUser } from './social/leaderboard-actions'
import { updateUserStatus } from './social/update-play-followers'
import { AppDispatch, RootState } from '../reducers'
import { MatchInfo, TrackInfoMap } from '../types'
import { startListeningToMatch } from './load-match'

type UserMatches = {
  active: string[]
  archived: string[]
}
const initRealtimeEndpoints = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('initRealtimeEndpoints', async (_, { dispatch, getState }) => {
  const isResetLocal = false // app.options.clearStorage; TODO?
  const username = selectCurrentUsername(getState())
  dispatch(updateUserStatus(userStatuses.LOGGED_IN))

  const addInvites = () => {
    realtime.endpoint(`user/${username}/invitesFrom`).on('value', function (snapshot) {
      const invites = snapshot.val() || {}
      dispatch(sessionSlice.actions.setMatchInvites(invites))
    })
  }

  realtime.endpointOnce(`user/${username}/info`, {}).then((userInfo) => {
    dispatch(initOrUpdateCurrentUser(userInfo))
    realtime.endpointOnce(`user/${username}/matches`, {}).then((userMatches) => {
      const { active: compoundActiveMatchSlugs = [], archived: compoundArchivedMatchSlugs = [] } =
        userMatches as UserMatches
      const userActiveMatches = compoundActiveMatchSlugs.map(Util.getCompoundMatchSlugParts)
      const userArchivedMatches = compoundArchivedMatchSlugs.map(Util.getCompoundMatchSlugParts)
      dispatch(
        sessionSlice.actions.setMatches({
          active: userActiveMatches,
          archived: userArchivedMatches,
        })
      )
      const referencedPlaylistSlugsByOwner: { [key: string]: Set<string> } = {}
      const activeMatchPromises = userActiveMatches.map(({ matchOwner, matchSlug }) => {
        return realtime.endpointOnce(`matches/${matchOwner}/${matchSlug}/info`, null)
      })
      userArchivedMatches.forEach(({ matchOwner, matchSlug }) => {
        realtime
          .endpointOnce(`matches/${matchOwner}/${matchSlug}/info`, defaultMatchInfo())
          .then((matchInfo) => {
            dispatch(
              matchStatusSlice.actions.updateMatchInfo({
                matchOwner,
                matchSlug,
                matchInfo, // TODO: make typesafe -- arrays could be undefined, etc.
              })
            )
          })
      })
      Promise.all(activeMatchPromises).then((matchResults) => {
        matchResults.forEach((rawMatchInfo) => {
          if (!rawMatchInfo) {
            return // can happen if active matches contains orphaned matchSlug TODO: remove from active matches!
          }
          const matchInfo: MatchInfo = { ...defaultMatchInfo(), ...(rawMatchInfo as MatchInfo) }
          const { slug: compoundMatchSlug, playlistOrder = [] } = matchInfo
          const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
          if (!(matchOwner in referencedPlaylistSlugsByOwner)) {
            referencedPlaylistSlugsByOwner[matchOwner] = new Set()
          }
          const referencedPlaylists = referencedPlaylistSlugsByOwner[matchOwner]
          playlistOrder.forEach((playlistSlug: string) => {
            referencedPlaylists.add(playlistSlug)
          })
          dispatch(
            matchStatusSlice.actions.updateMatchInfo({
              matchOwner,
              matchSlug,
              matchInfo,
            })
          )
          realtime.endpointOnce(`matches/${compoundMatchSlug}/invites`, {}).then((matchInvites) => {
            // TODO: filter this to just store the current user's invite (sufficient for sharing)
            dispatch(
              matchStatusSlice.actions.updateMatchInvites({
                matchOwner,
                matchSlug,
                matchInvites,
              })
            )
          })
          dispatch(startListeningToMatch({ matchOwner, matchSlug }))
        })
        const referencedOwners = Object.keys(referencedPlaylistSlugsByOwner)
        const loadTracksPromises = referencedOwners.map((owner) => {
          return realtime.endpointOnce(`tracks/${owner}`)
        })
        Promise.all(loadTracksPromises).then((ownerTracks) => {
          if (!isMobile()) {
            dispatch(initLocalAuthoringFromStorage(isResetLocal))
          }
          referencedOwners.forEach((owner, ownerIndex) => {
            const tracks: TrackInfoMap = ownerTracks[ownerIndex] || {}
            dispatch(blasterPeersSlice.actions.setTracks({ username: owner, tracks })) // TODO: filter only by public?
          })
          addInvites()
        })
      })
    })
  })
})

export default initRealtimeEndpoints
