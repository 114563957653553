import { createAsyncThunk } from '@reduxjs/toolkit'
import semver from 'semver/preload'
import * as Tone from 'tone'

import {
  DEFAULT_AUDIO_TYPE,
  DEFAULT_IMAGE_TYPE,
  DEFAULT_SEMVER,
  LAT_SEMVER_0_1_0,
  URL_PLACEHOLDER,
} from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import player from '../../services/TrackMixer'
import { ExtendedTrackInfo } from '../../types'
import { constructAudioUrl, constructImageUrl } from '../../util/track-utils'
import Util from '../../util/util'

const getTrackInfo = createAsyncThunk<
  Promise<ExtendedTrackInfo>,
  ExtendedTrackInfo,
  { state: RootState; dispatch: AppDispatch }
>('load/trackInfo', async (defaultTrackInfo, { dispatch, getState }) => {
  const trackInfo = {
    // TODO: better way to clone?
    ...defaultTrackInfo,
    paths: { ...defaultTrackInfo.paths },
  }
  const {
    slug,
    owner,
    paths: { infoUrl },
  } = trackInfo

  return Util.get(infoUrl).then(
    (infoJSON) => {
      try {
        const info = JSON.parse(String(infoJSON))
        const {
          title,
          artist,
          media,
          attributes: { duration, wordCount, timedWordCount },
          version,
        } = info
        const semVer = DEFAULT_SEMVER(version)
        trackInfo.title = title
        trackInfo.artist = artist
        trackInfo.version = semVer
        if (media) {
          const { links, audio, score, image } = media
          trackInfo.links = links || ''
          if (audio && audio[0]) {
            const defaultAudio = audio[0]
            const {
              home = owner,
              type = DEFAULT_AUDIO_TYPE,
              url = URL_PLACEHOLDER,
              timestamp = Date.now(),
            } = defaultAudio || {}
            trackInfo.timestamps.remoteAudioTimestamp = timestamp
            if (url && url !== URL_PLACEHOLDER) {
              trackInfo.paths.audioUrl = url
            } else {
              if (semver.satisfies(semVer, `>=${LAT_SEMVER_0_1_0}`)) {
                trackInfo.paths.audioUrl = constructAudioUrl({
                  timestamp,
                  owner: home || owner,
                  slug,
                  type,
                })
              } else {
                trackInfo.paths.audioUrl = `${trackInfo.paths.audioUrl}.${type}`
              }
            }
          }
          if (score && score[0]) {
            const { url = URL_PLACEHOLDER, timestamp = Date.now(), timing } = score[0]
            trackInfo.timestamps.remoteLyricsTimestamp = timestamp
            if (url && url !== URL_PLACEHOLDER) {
              trackInfo.paths.textUrl = url
            }
            if (timing && timing[0]) {
              const defaultTiming = timing[0]
              const { url = URL_PLACEHOLDER, timestamp } = defaultTiming || {}
              trackInfo.timestamps.remoteTimingTimestamp = timestamp
              if (url && url !== URL_PLACEHOLDER) {
                trackInfo.paths.lrcUrl = url
              }
            }
          }
          if (image && image[0]) {
            let imageUrl = ''
            const defaultImage = image[0]
            const {
              home = owner,
              type = DEFAULT_IMAGE_TYPE,
              url = URL_PLACEHOLDER,
              timestamp = Date.now(),
              blendMode = null,
              theme = null,
            } = defaultImage || {}
            trackInfo.timestamps.remoteImageTimestamp = timestamp
            if (url && url !== URL_PLACEHOLDER) {
              imageUrl = url
            } else {
              if (semver.satisfies(semVer, `>=${LAT_SEMVER_0_1_0}`)) {
                imageUrl = constructImageUrl({
                  timestamp,
                  owner: home || owner,
                  slug,
                  type,
                })
              } else {
                imageUrl = `${trackInfo.paths.imageUrl}.${type}`
              }
            }
            trackInfo.paths.imageUrl = imageUrl
            trackInfo.remoteImagePath = imageUrl
            trackInfo.imageAttributes = {
              blendMode,
              theme,
            }
          }
          if (wordCount) {
            trackInfo.wordCount = wordCount
          }
          if (timedWordCount) {
            trackInfo.timedWordCount = timedWordCount
          }
          if (duration) {
            trackInfo.duration = duration
          }
        }
      } catch (error) {
        console.error('error loading getting track info', error)
      }
      return trackInfo
    },
    (err) => {
      console.log(`track info not found: ${trackInfo.paths.infoUrl}`)
      trackInfo.paths.audioUrl = `${trackInfo.paths.audioUrl}.${DEFAULT_AUDIO_TYPE}`
      return trackInfo
    }
  )
})

const getTrackText = createAsyncThunk<
  string | Promise<unknown>,
  { textUrl?: string; text?: string },
  { state: RootState; dispatch: AppDispatch }
>('load/trackText', async ({ textUrl, text }, { dispatch, getState }) => {
  if (!textUrl) {
    return Promise.resolve(text)
  }
  try {
    const lyrics = await Util.get(textUrl)
    return lyrics
  } catch (error) {
    console.warn(`Couldn't load ${textUrl}: ${error}`)
    return 'lyrics not found'
  }
})

// TODO: harden each of these loaders (and callers) to handle missing / error consistently
const getTrackTiming = createAsyncThunk<
  string | Promise<unknown>,
  { lrcUrl?: string; lrcLines?: string },
  { state: RootState; dispatch: AppDispatch }
>('load/trackTiming', async ({ lrcUrl, lrcLines }, { dispatch, getState }) => {
  if (!lrcUrl) {
    return Promise.resolve(lrcLines)
  }
  try {
    const timing = await Util.get(lrcUrl)
    return timing
  } catch (error) {
    console.warn(`Couldn't load ${lrcUrl}: ${error}`)
    return null
  }
})

const setAudio = createAsyncThunk<
  number,
  { audioUrl: any; fileName?: string; isLogLoadTime?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('load/setAudio', ({ audioUrl, fileName = '', isLogLoadTime = true }) => {
  const fileStr = fileName || (typeof audioUrl === 'string' ? audioUrl : '?')
  const startMillis = Date.now()
  return new Promise<number>((resolve, reject) => {
    const buffer = new Tone.Buffer(
      audioUrl,
      function () {
        player.playerBuffer = buffer
        const loadTime = Util.secondsToClock((Date.now() - startMillis) / 1000)
        if (isLogLoadTime) {
          console.log(
            `${fileStr} load time: ${loadTime}, audio duration: ${Util.secondsToClock(
              buffer.duration
            )}`
          )
        }
        resolve(buffer.duration)
      },
      function (err) {
        player.playerBuffer = null
        console.log(`setAudio failed: ${fileStr}`, err) // TODO: notify user?
        resolve(0)
      }
    )
  })
})

export { getTrackText, getTrackTiming, getTrackInfo, setAudio }
