import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, { ChangeEvent, KeyboardEvent } from 'react'

import { setDefaultVisualization } from '../../actions/viz-switch'
import { useAppSelector, useAppDispatch } from '../../hooks'
import vizParametersSlice from '../../reducers/vizParametersSlice'
import trackMixer from '../../services/TrackMixer'
import { VizType } from '../../types'
import { incrementingKeyHandler } from '../../util/key-handlers'
import VizParamModal from '../modals/VizParamModal'
import { CheckboxWithLabel, SelectWithLabel } from '../widgets/TextInputWithLabel'

type Props = {
  playerIndex: number
}
const VizSettings = ({ playerIndex }: Props) => {
  const dispatch = useAppDispatch()
  const [isShowModal, setIsShowModal] = React.useState(false)
  const [isShowSettings, setIsShowSettings] = React.useState(false)
  const { activeVizKey, controls } = useAppSelector((state) => state.vizParams)
  const visualizations = [
    { value: 'page', name: 'Page' },
    { value: 'text1', name: 'Lines' },
    { value: 'wave', name: 'Wave' },
    { value: 'spiral', name: 'Spiral' },
    { value: 'flower', name: 'Flower' },
  ]
  const vizMenuRef = React.createRef<HTMLSelectElement>()
  const gamer = trackMixer._gamers[playerIndex].gamer
  const setNewViz = (vizSlug: VizType) => {
    dispatch(setDefaultVisualization({ gamer, vizSlug }))
  }
  const onVizMenuChange = (event: ChangeEvent<HTMLInputElement>) => {
    const vizSlug = event.target.value
    if (!vizSlug) {
      return // menu divider
    }
    setNewViz(vizSlug as VizType)
  }
  const onKeyDown = (event: KeyboardEvent) => {
    const step = event.keyCode === 39 ? 1 : event.keyCode === 37 ? -1 : 0
    if (vizMenuRef.current && step !== 0) {
      const newIndex =
        (vizMenuRef.current.selectedIndex + step + visualizations.length) % visualizations.length
      vizMenuRef.current.selectedIndex = newIndex
      setNewViz(visualizations[newIndex].value as VizType)
      event.stopPropagation()
    }
  }
  const onToggleSettings = () => {
    setIsShowSettings(!isShowSettings)
  }
  const onAnimateToggled = (event: ChangeEvent<HTMLInputElement>) => {
    gamer.vizBuilder.isAnimateViz = event.target.checked
  }

  // TODO: can't use this until visualization not dependent on values of control elements (which haven't yet been created)
  const getSettings = () => {
    const settings = Object.keys(controls).map(function (key) {
      const control = controls[key]
      const { label, type, initialValue } = control
      const onSetControlInputRef = (elem: any) => {
        if (elem) {
          // vizBuilder.setElement(key, elem);
        }
      }
      const updateControlValue = (value: string | number) => {
        dispatch(vizParametersSlice.actions.updateControlValue({ key, value }))
        gamer.vizBuilder.updateViz()
      }
      const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (type === 'color') {
          updateControlValue(event.target.value)
        } else {
          const currValue = parseFloat(event.target.value)
          if (!isNaN(currValue)) {
            updateControlValue(currValue)
          }
        }
      }
      const onKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
        incrementingKeyHandler(event, updateControlValue)
      }
      const onLabelClick = () => {
        dispatch(vizParametersSlice.actions.setActiveControlKey(key))
        setIsShowModal(true)
      }
      return (
        <div key={`control-${key}`} className="vizSetting">
          <label className="clickable" onClick={onLabelClick}>{`${label}: `}</label>
          <input
            ref={onSetControlInputRef}
            type={type}
            value={initialValue}
            onChange={onChange}
            onKeyDown={onKeydown}
          />
        </div>
      )
    })
    return settings
  }

  return (
    <div className="viz-ui">
      <div className="layout">
        <FontAwesomeIcon
          className="disclosure"
          icon={(isShowSettings ? faCaretDown : faCaretRight) as IconProp}
          title={`${isShowSettings ? 'hide' : 'show'} settings`}
          size="sm"
          onClick={onToggleSettings}
        />
        <SelectWithLabel
          id="layout"
          label="Layout"
          onRef={vizMenuRef}
          itemArray={visualizations}
          value={activeVizKey}
          onChange={onVizMenuChange}
          onKeyDown={onKeyDown}
        />
        <CheckboxWithLabel
          id="isAnimate"
          label="Animate"
          value={gamer.vizBuilder.isAnimateViz}
          onChange={onAnimateToggled}
        />
      </div>
      {isShowSettings && <div className="viz-settings">{getSettings()}</div>}
      {isShowModal && (
        <VizParamModal
          onClose={() => {
            setIsShowModal(false)
          }}
        />
      )}
    </div>
  )
}

export default VizSettings
