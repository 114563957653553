import cx from 'classnames'
import React from 'react'
import ReactCountdownClock from 'react-countdown-clock'

import { activateNextPane } from '../../actions/play-actions'
import spinner from '../../assets/img/spinner.gif'
import {
  COUNTDOWN_CLOCK_SECONDS,
  COUNTDOWN_CLOCK_SIZE,
  Pane,
  PaneClasses,
  VIZ_CONTAINER_CSS_PREFIX,
} from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIsFocus } from '../../reducers/focusSlice'
import { selectMode, selectPrefs, selectVisualizationInfo } from '../../selectors/session-selectors'
import LyricEditModal from '../modals/LyricEditModal'
import { LoadingPhases, LoadStatus } from '../../types'
import { selectCurrentGamers, selectIsPlaying } from '../../selectors/current-play-selectors'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import sessionSlice from '../../reducers/sessionSlice'
import player from '../../services/TrackMixer'
import themes from '../themes'

const LOAD_PHASES = {
  clearing: 'clearing previous',
  info: 'info',
  track: 'track resources',
  text: 'text',
  timing: 'timing',
  audio: 'audio',
  processing: 'preparing',
}

const InteractionView = () => {
  const dispatch = useAppDispatch()
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.PLAY))
  const mode = useAppSelector(selectMode)
  const lyricEditRange = useAppSelector((state) => state.modals.lyricEditRange)
  const trackLoadStatus = useAppSelector((state) => state.loading || {})
  const trackError = useAppSelector((state) => state.session.trackError)
  const visualizationInfo = useAppSelector(selectVisualizationInfo)
  const isShowCountdownClock = useAppSelector((state) => state.session.isShowCountdownClock)
  const isPlaying = useAppSelector(selectIsPlaying)
  const { theme: currentThemeIndex } = useAppSelector(selectPrefs)
  const gamers = useAppSelector(selectCurrentGamers)
  const isLoading = LoadingPhases.some((key) => {
    const status = trackLoadStatus[key]
    return status === LoadStatus.LOADING || status === LoadStatus.ERROR
  })
  const displayLoadingStatus = () => {
    return (
      <div className="loadingContainer">
        {LoadingPhases.map((phase) => {
          const status = trackLoadStatus[phase]
          if (status === LoadStatus.IDLE) {
            return null
          }
          const phaseName = LOAD_PHASES[phase]
          const isSuccess = status === LoadStatus.SUCCESS
          const isError = status === LoadStatus.ERROR
          const classname = cx({
            loadingStatus: true,
            isLoading: !isSuccess,
          })
          const message = isSuccess ? 'success' : isError ? 'error' : '...'
          return (
            <div id={phase} key={phase} className="loadingStatus">
              {phaseName}
              <span className={classname}>{message}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const onClick = () => {
    // console.log(`container: ${PaneClasses[Pane.PLAY]}`)
    dispatch(activateNextPane({ pane: Pane.PLAY }))
  }
  const wrapperClassname = cx(PaneClasses[Pane.PLAY], {
    hasFocus: isCurrFocus,
    isPlaying,
    [`${mode}Mode`]: true,
  })
  const _getCountdownClock = () => {
    const myCallback = () => {
      // TODO: move w/wo countdown flag to player.start(isCountdown: boolean)?
      player.start()
      dispatch(currentPlaySlice.actions.setIsPlaying(true))
      dispatch(sessionSlice.actions.setIsShowCountdownClock(false))
    }
    const color = themes[currentThemeIndex].cssVars['color-txt-title']
    if (isShowCountdownClock) {
      return (
        <ReactCountdownClock
          seconds={COUNTDOWN_CLOCK_SECONDS}
          color={color}
          alpha={0.6}
          size={COUNTDOWN_CLOCK_SIZE}
          onComplete={myCallback}
          showMilliseconds={false}
        />
      )
    }
  }
  const activePlayers = gamers.filter(({ isActive }, index) => isActive || index === 0)
  return (
    <div className={wrapperClassname} onClick={onClick} tabIndex={0}>
      <div id="interactionContainer"></div>
      {activePlayers.map(({ isActive }, index) => {
        const containerClassname = cx({
          interactionContainer: true,
          [`${VIZ_CONTAINER_CSS_PREFIX}${visualizationInfo[index].currentVizSlug}`]: true,
          hidden: !isActive || isLoading || trackError || lyricEditRange,
          notFirst: index > 0,
        })
        return (
          <div key={`${index}`} id={`gameContainer-${index}`} className={containerClassname}></div>
        )
      })}
      {_getCountdownClock()}
      {lyricEditRange && <LyricEditModal selectedRange={lyricEditRange} />}
      {isLoading && (
        <div className="interactionContainer">
          <div className="newLoadingIndicator">
            <img className="spinner" src={spinner} alt="spinner" />
            {displayLoadingStatus()}
          </div>
        </div>
      )}
    </div>
  )
}

export default InteractionView
