import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../reducers'
import settingsSlice from '../reducers/settingsSlice'
import player from '../services/TrackMixer'
import scheduleVizSwitches from './viz-switch'
import scheduleSectionAnimations from './section-animation'

const toggleShowSections = createAsyncThunk<
  void,
  boolean,
  { state: RootState; dispatch: AppDispatch }
>('playAction/toggleShowSections', (isShow: boolean, { dispatch, getState }) => {
  player.defaultGamer.toggleSections(isShow)
  dispatch(settingsSlice.actions.setIsShowSections({ isShowSections: isShow }))
})

const toggleShowSectionAnimation = createAsyncThunk<
  void,
  boolean,
  { state: RootState; dispatch: AppDispatch }
>('playAction/toggleShowSectionAnimation', (isShowSectionAnimation, { dispatch, getState }) => {
  dispatch(settingsSlice.actions.setShowSectionAnimation({ isShowSectionAnimation }))
  dispatch(scheduleSectionAnimations(player.defaultGamer))
})

const toggleVizSwitchingEnabled = createAsyncThunk<
  void,
  { playerIndex: number; isEnabled: boolean },
  { state: RootState; dispatch: AppDispatch }
>('playAction/toggleVizSwitchingEnabled', ({ playerIndex, isEnabled }, { dispatch, getState }) => {
  dispatch(settingsSlice.actions.setIsVizSwitchingEnabled({ playerIndex, isEnabled })) // TODO
  const gamer = player._gamers[playerIndex].gamer
  dispatch(scheduleVizSwitches(gamer))
})

const toggleNoviceAssistEnabled = createAsyncThunk<
  void,
  boolean,
  { state: RootState; dispatch: AppDispatch }
>(
  'playAction/toggleNoviceAssistEnabled',
  (isNoviceAssistEnabled: boolean, { dispatch, getState }) => {
    player.defaultGamer.toggleNoviceAssist(isNoviceAssistEnabled)
    dispatch(settingsSlice.actions.setIsNoviceAssistEnabled({ isNoviceAssistEnabled }))
  }
)

export {
  toggleShowSections,
  toggleNoviceAssistEnabled,
  toggleVizSwitchingEnabled,
  toggleShowSectionAnimation,
}
