import doSave from '../actions/save'
import { activateNextPane, togglePlay } from '../actions/play-actions'
import modalsSlice from '../reducers/modalsSlice'
import settingsSlice from '../reducers/settingsSlice'
import { clearWordScoringClasses } from '../util/score-utils'
import trackMixer from './TrackMixer'
import { AppDispatch } from '../reducers'
import { Word } from '../types'
import GamepadService from './GamepadService'
import Gamepad from './Gamepad'
import userManager from './UserManager'
import Gamer from './Gamer'

type ButtonCallbackMap = { [button: string]: () => void }

class KeyBindings {
  _dispatch: AppDispatch | null
  _gamepadService: GamepadService
  _containerStack: Element[]
  _navCallback: ((horizStep: number, vertStep: number) => void) | null
  constructor() {
    this._dispatch = null
    this._gamepadService = new GamepadService()
    this._containerStack = []
    this._navCallback = null
  }
  get dispatch(): AppDispatch {
    if (!this._dispatch) {
      throw new Error('dispatch not set')
    }
    return this._dispatch
  }
  init(dispatch: AppDispatch) {
    this._dispatch = dispatch
    window.addEventListener('keydown', this.handleKeydown.bind(this))
    window.addEventListener('keyup', this.handleKeyup.bind(this))
    this._gamepadService.on('connect', (gamepad: Gamepad) => {
      console.log('gamepad', gamepad)
      this.addGamepadListeners(gamepad)
    })
  }
  onModalContainerRef(container: Element | null, isPane?: boolean) {
    if (container && container !== this.currentContainer) {
      if (isPane) {
        this._containerStack = [container]
      } else {
        this._containerStack.push(container)
      }
      this.focusNextField(1, 0)
    }
  }
  popModal() {
    this._containerStack.pop()
    this.focusNextField(1, 0) // TODO: ideally, this should be previously selected field
  }
  clearContainers() {
    this._containerStack = []
  }
  get currentContainer() {
    const topContainerIndex = this._containerStack.length - 1
    return topContainerIndex >= 0 ? this._containerStack[topContainerIndex] : null
  }
  focusNextField(horizStep: number, vertStep: number) {
    if (this._navCallback) {
      this._navCallback(horizStep, vertStep)
      return
    }
    const container = this.currentContainer
    if (!container) {
      return
    }
    // Get a list of all focusable elements
    const focusableElements = Array.from(
      container.querySelectorAll('input, select, textarea, button, a[href], [tabindex]')
    ).filter((elem) => {
      const htmlElem = elem as HTMLInputElement
      return htmlElem.tabIndex !== -1 && !htmlElem.disabled
    })
    const startingField = document.activeElement
    // Get the index of the current field
    const currentIndex = startingField ? focusableElements.indexOf(startingField) : -1

    // Move to the next field and handle wrapping around to the first element
    const step = horizStep !== 0 ? horizStep : vertStep
    const nextIndex = (currentIndex + step + focusableElements.length) % focusableElements.length
    const nextField = focusableElements[nextIndex]

    // Set focus to the next field
    if (nextField) {
      // console.log('nextField', nextField)
      ;(nextField as HTMLElement).focus()
    }
  }
  get isInModal() {
    return this._containerStack.length > 1
  }
  getActivePlayerAt(controllerIndex: number) {
    const activePlayers = trackMixer._gamers.filter(({ isActive }) => isActive)
    const numPlayers = activePlayers.length
    const activePlayerIndex =
      numPlayers > controllerIndex ? controllerIndex : numPlayers > 0 ? 0 : -1
    return activePlayerIndex >= 0 ? activePlayers[activePlayerIndex] : null
  }

  handleKeyup(event: KeyboardEvent) {
    const isRight = event.location === KeyboardEvent.DOM_KEY_LOCATION_RIGHT
    const activePlayer = this.getActivePlayerAt(isRight ? 1 : 0)
    if (!activePlayer) {
      return
    }
    switch (event.keyCode) {
      case 16: // SHIFT key
        activePlayer.gamer.releaseBlast(isRight)
        break

      case 18: // Alt key
        this.dispatch(settingsSlice.actions.setIsOptionKey({ isOptionKey: false }))
        break

      default:
    }
  }
  handleKeydown(event: KeyboardEvent) {
    const { metaKey, keyCode, altKey, shiftKey, key, location } = event
    const isRight = location === KeyboardEvent.DOM_KEY_LOCATION_RIGHT
    const activePlayer = this.getActivePlayerAt(isRight ? 1 : 0)
    if (!activePlayer) {
      return
    }
    const { gamer: player } = activePlayer
    let handled = false

    if (metaKey) {
      switch (keyCode) {
        case 83: // 's' key
          this.dispatch(doSave({ isDownload: altKey, gamer: player }))
          handled = true
          break
        case 85: // 'u' key
          // app.gameSettings.doSync(event.altKey); // TODO
          handled = true
          break
        case 186: // ';' key
          // app.gameSettings.trackInfoModal.toggle(true); // TODO
          handled = true
          break
        case 39: // right arrow
          if (!this.isInModal && !this.isPubMode) {
            this.dispatch(activateNextPane({ direction: 1 }))
            handled = true
          }
          break
        default:
      }
    }
    if (handled) {
      event.preventDefault()
      return
    }
    handled = true
    switch (keyCode) {
      case 32: // SPACE key
        if (!trackMixer.isSpellMode) {
          this.dispatch(togglePlay())
        } else {
          handled = false
        }
        break

      case 91: // LEFT CMD key
      case 93: // RIGHT CMD key
      case 16: // SHIFT key
        player.blast(isRight)
        break

      case 13: // RETURN key
        if (player.hasFocus) {
          player.rewindToStart()
        } else {
          ;(document.activeElement as HTMLElement).click()
          // handled = false
        }
        break

      case 39: // Right arrow
        if (player.hasFocus) {
          const isSeek = !shiftKey
          player._loadNextWord(isSeek)
        } else {
          this.focusNextField(1, 0)
        }
        break

      case 37: // Left arrow
        if (player.hasFocus) {
          const isPlayHead = shiftKey
          player._loadPrevWord(isPlayHead)
        } else {
          this.focusNextField(-1, 0)
        }
        break

      case 8: // backspace/delete key
        if (altKey) {
          player.clearTiming()
        } else {
          if (player.target.elem) {
            const word = player.targetWord
            if (word) {
              if (trackMixer.isVizMode) {
                clearWordScoringClasses(word as Word)
              } else {
                player.clearWord(word as Word, true)
                player._updateDirty()
              }
            }
          }
          player._loadPrevWord()
          player.stop()
        }
        break

      case 38: // Up arrow
        if (player.hasFocus) {
          const isPlayHead = shiftKey
          player._loadPrevLine(isPlayHead)
        } else {
          this.focusNextField(0, -1)
        }
        break

      case 40: // Down arrow
        if (player.hasFocus) {
          const isSeek = !shiftKey
          player._loadNextLine(isSeek)
        } else {
          this.focusNextField(0, 1)
        }
        break

      case 18: // Alt key
        this.dispatch(settingsSlice.actions.setIsOptionKey({ isOptionKey: true }))
        break

      case 27: // Esc key
        this.handleEscape()
        break

      case 191: // / key
        this.dispatch(modalsSlice.actions.toggleHelpModal(true))
        break

      default:
        handled = false
        if (trackMixer.isSpellMode) {
          player.appendSpelling(key)
        }
    }

    if (handled) {
      event.preventDefault()
    }
  }

  get isPubMode() {
    return userManager.audience === 'pub'
  }
  handleEscape() {
    const closeIcons = document.querySelectorAll('.closeIcon.top')
    if (closeIcons.length) {
      ;(closeIcons[0] as HTMLElement).click()
    }
  }
  handleArrowRight(player: Gamer, shiftKey = false) {
    if (player.hasFocus) {
      const isSeek = !shiftKey
      player._loadNextWord(isSeek)
    } else {
      this.focusNextField(1, 0)
    }
  }
  handleArrowLeft(player: Gamer, shiftKey = false) {
    if (player.hasFocus) {
      const isPlayHead = shiftKey
      player._loadPrevWord(isPlayHead)
    } else {
      this.focusNextField(-1, 0)
    }
  }
  handleArrowUp(player: Gamer, shiftKey = false) {
    if (player.hasFocus) {
      const isPlayHead = shiftKey
      player._loadPrevLine(isPlayHead)
    } else {
      this.focusNextField(0, -1)
    }
  }
  handleArrowDown(player: Gamer, shiftKey = false) {
    if (player.hasFocus) {
      const isSeek = !shiftKey
      player._loadNextLine(isSeek)
    } else {
      this.focusNextField(0, 1)
    }
  }
  addGamepadListeners(gamepad: Gamepad) {
    const { index } = gamepad
    const gamerIndex = index < trackMixer._gamers.length ? index : 0
    const player = trackMixer._gamers[gamerIndex].gamer
    const escape = () => {
      if (this.isPubMode) {
        return // pub mode player is never navigating modals
      }
      this.handleEscape()
    }
    const nextPane = () => {
      if (this.isPubMode) {
        return // pub mode player is never switching panes
      }
      this.dispatch(activateNextPane({ direction: 1 }))
    }
    const clickSelected = () => {
      ;(document.activeElement as HTMLElement).click()
    }
    const clearOrRewind = () => {
      if (this.isInModal) {
        return
      }
      if (trackMixer.hasFocus && !trackMixer.isPlaying) {
        player.clearTiming()
      } else {
        player.rewindToStart()
      }
    }
    const unpress = (isRight: boolean) => {
      player.releaseBlast(isRight)
    }
    const blast = (isRight: boolean) => {
      if (this.isInModal) {
        return
      }
      player.blast(isRight)
    }
    const up = () => {
      if (player.hasFocus) {
        player._loadPrevLine(false)
      } else {
        this.handleArrowUp(player)
        // simulateKeyPress('ArrowUp', 38)
      }
    }
    const down = () => {
      if (player.hasFocus) {
        player._loadNextLine(true)
      } else {
        this.handleArrowDown(player)
        // simulateKeyPress('ArrowDown', 40)
      }
    }
    const left = () => {
      if (player.hasFocus) {
        player._loadPrevWord(false)
      } else {
        this.focusNextField(-1, 0)
      }
    }
    const right = () => {
      if (player.hasFocus) {
        player._loadNextWord(true)
      } else {
        this.focusNextField(1, 0)
      }
    }
    // TODO: perhaps allow/use in some admin mode?
    // const refreshPage = () => {
    //   if (!player.isPlaying) {
    //     // eslint-disable-next-line
    //     window.location.href = window.location.href
    //   }
    // }
    const playOrPause = () => {
      if (this.isInModal) {
        return
      }
      this.dispatch(togglePlay())
    }
    const mappings: ButtonCallbackMap = {
      button0: () => {
        // console.log('button0 (North?) escape')
        escape()
      },
      button1: () => {
        // console.info('button1 (East?) next pane')
        nextPane()
      },
      button2: () => {
        // console.log('button2 (South?) click!')
        // TODO: what should this be?
      },
      button3: () => {
        // console.log('button3 (West?) rewind/clear')
        clearOrRewind()
      },
      button4: () => {
        // console.log(`${gamerIndex}: button4 (front top left)`)
        blast(true)
      },
      button5: () => {
        // console.log(`${gamerIndex}: button5 (front top right)`)
        blast(false)
      },
      button6: () => {
        // console.log('button6 (front bottom left)')
        blast(true)
      },
      button7: () => {
        // console.log('button7 (front bottom right)')
        blast(false)
      },
      button8: () => {
        // console.log('button8 (select)')
        clickSelected()
      },
      button9: () => {
        // console.log('button9 (start)')
        playOrPause()
      },
      button10: () => {
        // console.log('button10')
      },
      button11: () => {
        // console.log('button11')
      },
      button12: () => {
        // console.log('button12 (up)')
        up()
      },
      button13: () => {
        // console.log('button13 (down)')
        down()
      },
      button14: () => {
        // console.log('button14 (left)')
        left()
      },
      button15: () => {
        // console.log('button15 (right)')
        right()
      },
      button16: () => {
        // console.log('button16')
      },
      button17: () => {
        // console.log('button17')
      },
      left0: () => {
        // console.log('left0')
        left()
      },
      right0: () => {
        // console.log('right0')
        right()
      },
      up0: () => {
        // console.log('up0')
        up()
      },
      down0: () => {
        // console.log('down0')
        down()
      },
      left1: () => {
        // console.log('left1')
      },
      right1: () => {
        // console.log('right1')
      },
      up1: () => {
        // console.log('up1')
      },
      down1: () => {
        // console.log('down1')
      },
    }
    const afterMappings: ButtonCallbackMap = {
      button4: () => {
        // console.log('button4 after')
        unpress(false)
      },
      button5: () => {
        // console.log('button5 after')
        unpress(true)
      },
      button6: () => {
        // console.log('button6 after')
        unpress(false)
      },
      button7: () => {
        // console.log('button7 after')
        unpress(true)
      },
    }

    Object.keys(mappings).forEach((key) => {
      // TODO: because gamepad.before will just log a generic error if button doesn't exist
      // (e.g. button16 and 17 with Mika's "havit" controller)
      // we can't easily handle. we could hardcode known cases to provide better logging or feedback
      // -- or perhaps file an issue or contribute a PR to improve error-handling for the module
      gamepad.before(key, mappings[key])
    })
    Object.keys(afterMappings).forEach((key) => {
      // TODO: because gamepad.before will just log a generic error if button doesn't exist
      // (e.g. button16 and 17 with Mika's "havit" controller)
      // we can't easily handle. we could hardcode known cases to provide better logging or feedback
      // -- or perhaps file an issue or contribute a PR to improve error-handling for the module
      gamepad.after(key, afterMappings[key])
    })
  }
}

const defaultKeyBindings = new KeyBindings()
export default defaultKeyBindings
