import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'

import {
  addOrUpdateMatchInfo,
  switchActiveBlaster,
} from '../../../actions/social/leaderboard-actions'
import { defaultPlayerScore } from '../../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectCurrentGamers } from '../../../selectors/current-play-selectors'
import { selectMatchStatus } from '../../../selectors/match-selectors'
import { selectCurrentUsername } from '../../../selectors/session-selectors'
import { GuestPlayer, MatchStatus, ScoreRank } from '../../../types'
import Util from '../../../util/util'
import PlayerEditModal from './PlayerEditModal'

type Props = {
  compoundMatchSlug: string
  isShowSectionHeading?: boolean
  isShowPlayerIds?: boolean
}
type GuestPlayerItem = GuestPlayer & {
  id: string
  blasterIndex: number
  isGuest: boolean
  topScore: number
  topScoreRank: ScoreRank
}

const GuestPlayers = ({
  compoundMatchSlug,
  isShowSectionHeading = true,
  isShowPlayerIds = false,
}: Props) => {
  const dispatch = useAppDispatch()
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const currentUsername = useAppSelector(selectCurrentUsername)
  const matchStatus: MatchStatus = useAppSelector(selectMatchStatus(matchOwner, matchSlug))
  const gamers = useAppSelector(selectCurrentGamers)
  const { leaderboard, players, info: matchInfo } = matchStatus
  const { guestOrder: currentGuestOrder = [] } = matchInfo
  const [guestPlayers, setGuestPlayers] = useState<GuestPlayerItem[]>([])
  const [selectedPlayerInfo, setSelectedPlayerInfo] = useState<GuestPlayer | null>(null)
  const [workingGuestOrder, setWorkingGuestOrder] = useState<string[]>(currentGuestOrder)
  const [isDirty, setIsDirty] = useState(false)
  const [indexToSwitchTo, setIndexToSwitchTo] = useState(0)
  const activeGamerIds = gamers.filter(({ isActive }) => isActive).map(({ gamerId }) => gamerId)

  useEffect(() => {
    setWorkingGuestOrder(currentGuestOrder)
  }, [currentGuestOrder])

  useEffect(() => {
    const currentGuestPlayerItems = workingGuestOrder.map((playerId) => {
      const playerInfo = players[currentUsername][playerId]
      if (!playerInfo) {
        console.log(`player info missing for ${playerId}`)
      }
      const {
        name,
        matchScore: { topScore, topScoreRank },
      } = playerInfo || defaultPlayerScore() // TODO: this shouldn't fail, but bugs can (have) caused it to; filter currentGuestPlayers first?
      const blasterIndex = activeGamerIds.indexOf(playerId)
      return {
        name,
        slug: playerId,
        id: `${currentUsername}/${playerId}`,
        isGuest: true,
        blasterIndex,
        topScore,
        topScoreRank,
      }
    })
    setGuestPlayers(currentGuestPlayerItems)
  }, [currentUsername, players, workingGuestOrder, gamers])

  const _displayPlayer = (
    { id, name, slug, topScore, topScoreRank, isGuest, blasterIndex }: GuestPlayerItem,
    playerIndex: number
  ) => {
    const isActive = blasterIndex >= 0
    const blastButtonClassname = cx('blastButton', {
      mini: true,
      left: blasterIndex === 0,
      inactive: !isActive,
    })
    const titleClassname = cx('title', { isGuest })
    const onPlayerClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (isGuest) {
        setSelectedPlayerInfo({ slug, name })
      }
      event.preventDefault() // otherwise list will get onChange
    }
    const onSwitchToPlayer = () => {
      if (isActive) {
        return // TODO: disable
      }
      dispatch(switchActiveBlaster({ gamerIndex: indexToSwitchTo, newBlasterSlug: slug }))
      setIndexToSwitchTo((indexToSwitchTo + 1) % activeGamerIds.length)
    }
    return (
      <div key={id} data-id={id} className="sortable-list-item player">
        <div className="left">
          <button className={blastButtonClassname} onClick={onSwitchToPlayer}>
            {!isActive && <FontAwesomeIcon size="lg" icon={faBolt as IconProp} title="Switch" />}
          </button>
          <div className="rank">{playerIndex + 1}</div>
          <div className={titleClassname}>
            <div onClick={onPlayerClick}>
              <div>{name || slug}</div>
              {isShowPlayerIds && <div className="id">{id}</div>}
            </div>
          </div>
        </div>
        <div className={`score`}>{Util.numberWithCommas(topScore)}</div>
      </div>
    )
  }
  const onSave = () => {
    const newMatchInfo = { ...matchInfo, guestOrder: workingGuestOrder }
    dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo }))
    setIsDirty(false)
  }
  const onShuffle = () => {
    const newOrder = Util.shuffleArray(guestPlayers.map(({ slug }) => slug))
    setWorkingGuestOrder(newOrder)
    setIsDirty(true)
  }
  const onSort = () => {
    const sortedGuestPlayers: string[] = []
    // const isAscending = true
    ;[...leaderboard].reverse().forEach((compoundPlayer) => {
      const [username, player] = compoundPlayer.split('/')
      if (username === currentUsername && player !== currentUsername) {
        sortedGuestPlayers.push(player)
      }
    })
    setWorkingGuestOrder(sortedGuestPlayers)
    setIsDirty(true)
  }

  const getPlayerEditModal = () => {
    if (selectedPlayerInfo === null) {
      return false
    }
    const onClose = () => {
      setSelectedPlayerInfo(null)
    }
    return (
      <PlayerEditModal
        matchSlug={compoundMatchSlug}
        player={selectedPlayerInfo}
        onClose={onClose}
      />
    )
  }
  const newPlayer = () => {
    /* TODO
      Currently, guest player ids are not unique across matches, only within matches.
      Perhaps they should be, but to keep things simple (from the hosts and players perspective),
      the ids aren't weird or even changeable, they're auto-assigned as player-1, player-2, etc.
      Guest players are not currently deletable, but since that may change, we can't assume that
      "guest-player count + 1" isn't already taken. This led me to the code below, which,
      as a side-effect, introduces a limit on the number guest players, which bears
      discussing at some point -- either before or after it ever gets hit :)
     */
    const guestPlayerCount = guestPlayers.filter(({ slug }) => !slug.startsWith('@')).length
    const MAX_GUESTS = 100
    let nextIndex = guestPlayerCount + 1
    let newSlug = ''

    while (nextIndex <= MAX_GUESTS && !newSlug) {
      const maybeNewSlug = `player-${nextIndex++}`
      if (!guestPlayers.find(({ slug }) => slug === maybeNewSlug)) {
        newSlug = maybeNewSlug
      }
    }
    if (!newSlug) {
      alert(`[${MAX_GUESTS}] guest player limit reached.`)
      return
    }
    const newPlayer = {
      slug: newSlug,
      name: `Player ${nextIndex - 1}`,
    }
    setSelectedPlayerInfo(newPlayer)
  }
  return (
    <>
      <div className="players">
        {isShowSectionHeading && <label>Players:</label>}
        <div>
          <button onClick={onShuffle}>Shuffle</button>
          <button onClick={onSort}>Sort</button>
          <button onClick={onSave} disabled={!isDirty}>
            Save Order
          </button>
        </div>
        <div className="playersDisplay">{guestPlayers.map(_displayPlayer)}</div>
        <button onClick={newPlayer}>New Player...</button>
      </div>
      {getPlayerEditModal()}
    </>
  )
}

export default GuestPlayers
