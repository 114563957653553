import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type GamerViewSettings = {
  isShowTimes: boolean
  isShowSections: boolean
  isShowSectionAnimation: boolean
  isShowLyrics: boolean
  isVisualPulse: boolean
  isAudioPulse: boolean
  isOptionKey: boolean
  isVizSwitchingEnabled: boolean
  isNoviceAssistEnabled: boolean
}
const defaultGamerViewSettings = () => ({
  isShowTimes: false,
  isShowSections: true,
  isShowSectionAnimation: true,
  isShowLyrics: true,
  isVisualPulse: false,
  isAudioPulse: false,
  isOptionKey: false,
  isVizSwitchingEnabled: true,
  isNoviceAssistEnabled: false,
})
export interface SettingsState {
  viewSettings: GamerViewSettings[]
}
const initialState: SettingsState = {
  viewSettings: [defaultGamerViewSettings(), defaultGamerViewSettings()],
}
const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIsShowTimes: (state, action: PayloadAction<{ isShowTimes: boolean }>) => {
      state.viewSettings[0].isShowTimes = action.payload.isShowTimes
    },
    setIsShowLyrics: (state, action: PayloadAction<{ isShowLyrics: boolean }>) => {
      state.viewSettings[0].isShowLyrics = action.payload.isShowLyrics
    },
    setIsShowSections: (state, action: PayloadAction<{ isShowSections: boolean }>) => {
      state.viewSettings[0].isShowSections = action.payload.isShowSections
    },
    setShowSectionAnimation: (
      state,
      action: PayloadAction<{ isShowSectionAnimation: boolean }>
    ) => {
      state.viewSettings[0].isShowSectionAnimation = action.payload.isShowSectionAnimation
    },
    setIsVizSwitchingEnabled: (
      state,
      action: PayloadAction<{ playerIndex: number; isEnabled: boolean }>
    ) => {
      const { playerIndex, isEnabled } = action.payload
      state.viewSettings[playerIndex].isVizSwitchingEnabled = isEnabled
    },
    setIsNoviceAssistEnabled: (
      state,
      action: PayloadAction<{ isNoviceAssistEnabled: boolean }>
    ) => {
      state.viewSettings[0].isNoviceAssistEnabled = action.payload.isNoviceAssistEnabled
    },
    setIsVisualPulse: (state, action: PayloadAction<{ isVisualPulse: boolean }>) => {
      state.viewSettings[0].isVisualPulse = action.payload.isVisualPulse
    },
    setIsAudioPulse: (state, action: PayloadAction<{ isAudioPulse: boolean }>) => {
      state.viewSettings[0].isAudioPulse = action.payload.isAudioPulse
    },
    setIsOptionKey: (state, action: PayloadAction<{ isOptionKey: boolean }>) => {
      state.viewSettings[0].isOptionKey = action.payload.isOptionKey
    },
  },
})

export default settingsSlice
