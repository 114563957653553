import { pathOr } from 'ramda'

import { RootState } from '../reducers'
import { ActiveMatchInfo } from '../types'
import { selectCurrentUser } from './blaster-peer-selectors'

const selectCurrentUsername = (state: RootState) => state.session.username
const selectCurrentBackgroundIndex = (state: RootState) => state.session.currentBackgroundIndex
const selectCurrentBackgroundBlendMode = (state: RootState) =>
  state.session.currentBackgroundBlendMode
const selectCurrentThemeIndex = (state: RootState) => state.session.currentThemeIndex
const selectCurrentMatchSlug = (state: RootState) => state.session.currentMatchSlug
const selectVisualizationInfo = (state: RootState) => state.session.visualizationInfo
const selectCurrentBlaster = (state: RootState) => state.currentPlay.gamers
const selectActiveMatches = (state: RootState): ActiveMatchInfo[] =>
  state.session.matches.active || []
const selectArchivedMatches = (state: RootState) => state.session.matches.archived || []
const selectMatchInvites = (state: RootState) => state.session.matchInvites
const selectAudience = (state: RootState) => state.session.prefs.audience
const selectAudienceFlags = (state: RootState) => {
  const audience = state.session.prefs.audience
  return {
    isPub: audience === 'pub',
    isCouch: audience === 'couch',
    isSolo: audience === 'solo',
    isProducer: audience === 'prod',
  }
}
const selectNumPlayers = (state: RootState) => (state.session.isMulti ? 2 : 1)
const selectMode = (state: RootState) => state.session.mode
const selectModeFlags = (state: RootState) => {
  const mode = state.session.mode
  return {
    isVizMode: mode === 'viz',
    isPlayMode: mode === 'play',
    isEditMode: mode === 'edit',
    isSpellMode: mode === 'spell',
  }
}
const selectIsShowSocial = (state: RootState) => state.session.isShowSocial
const selectIsShowGameSidebar = (state: RootState) => state.session.isShowGameSidebar
const selectIsShowSettings = (state: RootState) => state.session.isShowSettings
const selectSpotifyToken = (state: RootState) => state.session.spotifyToken
const selectHasSpotifyToken = (state: RootState) => state.session.spotifyToken.length > 0
const selectSpotifyPlayerState = (state: RootState) => state.session.spotifyPlayerState
const selectCurrentTrackImageUrl = (state: RootState) => {
  const images = pathOr(
    [],
    ['session', 'spotifyPlayerState', 'track_window', 'current_track', 'album', 'images'],
    state
  )

  return images.length ? (images[0] as Spotify.Image).url : ''
}
const selectIsSpotifyConnected = (state: RootState) => !!state.session.spotifyPlayerState

const selectCurrentSessionInfo = (state: RootState) => {
  const { username, currentMatchSlug, mode } = state.session
  const currentBlaster = state.currentPlay.gamers
  return { username, currentMatchSlug, mode, currentBlaster }
}

const selectIsCurrentOwnedMatch = (state: RootState) => {
  const currentMatchSlug = selectCurrentMatchSlug(state)
  const { ownedMatchSlug } = selectCurrentUser(state)
  return currentMatchSlug === ownedMatchSlug
}

const selectInviteForMatch = (matchSlugToFind: string) => (state: RootState) => {
  const activeMatches = state.session.matches.active
  if (activeMatches) {
    const activeMatch = activeMatches.find(
      ({ matchSlug }: ActiveMatchInfo) => matchSlug === matchSlugToFind
    )
    if (activeMatch) {
      return activeMatch.inviteKey
    }
  }
  const archivedMatch = state.session.matches.archived.find(
    ({ matchSlug }: ActiveMatchInfo) => matchSlug === matchSlugToFind
  )
  if (archivedMatch) {
    return archivedMatch.inviteKey
  }
  return '' // shouldn't happen
}

const selectPrefs = (state: RootState) => state.session.prefs

const selectUserInfo = (state: RootState) => {
  const { currUserAliasIndex, userAliases, username: currUsername } = state.session
  return { currUserAliasIndex, userAliases, currUsername }
}

const selectIsFullScreen = (state: RootState) => state.session.isFullscreen
export const selectPlayerVisibility = (state: RootState) => state.session.playerVisibility

export const selectIsOptionKey = (gamerIndex: number) => (state: RootState) =>
  state.settings.viewSettings[gamerIndex].isOptionKey
export const selectViewSettings = (gamerIndex: number) => (state: RootState) =>
  state.settings.viewSettings[gamerIndex]

export {
  selectActiveMatches,
  selectArchivedMatches,
  selectAudience,
  selectAudienceFlags,
  selectCurrentBackgroundBlendMode,
  selectCurrentBackgroundIndex,
  selectCurrentThemeIndex,
  selectVisualizationInfo,
  selectCurrentUsername,
  selectCurrentMatchSlug,
  selectCurrentBlaster,
  selectCurrentSessionInfo,
  selectCurrentTrackImageUrl,
  selectHasSpotifyToken,
  selectInviteForMatch,
  selectNumPlayers,
  selectIsCurrentOwnedMatch,
  selectIsFullScreen,
  selectIsShowGameSidebar,
  selectIsShowSocial,
  selectIsShowSettings,
  selectIsSpotifyConnected,
  selectMatchInvites,
  selectMode,
  selectModeFlags,
  selectPrefs,
  selectSpotifyPlayerState,
  selectSpotifyToken,
  selectUserInfo,
}
