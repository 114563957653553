import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  defaultMatchScore,
  defaultMatchStatus,
  defaultPlaylistInfo,
} from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import blasterPeersSlice from '../../reducers/blasterPeersSlice'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import leaderboardSlice from '../../reducers/leaderboardSlice'
import matchStatusSlice from '../../reducers/matchStatusSlice'
import sessionSlice from '../../reducers/sessionSlice'
import {
  selectOwnedMatchSlug,
  selectOwnedPlaylistMap,
  selectTrackToPlaylistsMap,
  selectUserTracksInfo,
} from '../../selectors/blaster-peer-selectors'
import {
  selectCurrentGamers,
  selectCurrentPlaylistSlug,
  selectCurrentTrackInfo,
  selectCurrentTrackSlug,
} from '../../selectors/current-play-selectors'
import {
  selectMatchInfo,
  selectMatchStatus,
  selectOwnerMatchStatuses,
  selectPlayerScore,
} from '../../selectors/match-selectors'
import {
  selectActiveMatches,
  selectCurrentSessionInfo,
  selectCurrentUsername,
  selectIsCurrentOwnedMatch,
} from '../../selectors/session-selectors'
import trackMixer from '../../services/TrackMixer'
import realtime from '../../services/RealtimeService'
import {
  GuestPlayer,
  InviteInfo,
  InvitePath,
  MatchInfo,
  MinimalInviteInfo,
  PlaylistInfoMap,
  TrackInfo,
  UserMatchesInfo,
  UserMatchInfo,
} from '../../types'
import { computeOwnerMatchScore, getTrackPoints } from '../../util/score-utils'
import Util from '../../util/util'
import { loadMatch, matchSwitchedTo } from '../load-match'

type UserInfo = {
  status: string
  timestamp: number
  ownedMatchSlug?: string
  allTracksPlaylistSlug?: string
}
type InitOrUpdatePayload = {
  ownedMatchSlug: string
  allTracksPlaylistSlug: string
  playlistInfo: {}
}

const initOrUpdateCurrentUser = createAsyncThunk<
  void,
  InitOrUpdatePayload,
  { state: RootState; dispatch: AppDispatch }
>('initOrUpdateCurrentUser', (payload, { dispatch, getState }) => {
  const { ownedMatchSlug, allTracksPlaylistSlug, playlistInfo } = payload
  const username = selectCurrentUsername(getState())
  const userInfo: UserInfo = {
    status: 'active',
    timestamp: Date.now(),
  }
  if (ownedMatchSlug && allTracksPlaylistSlug) {
    userInfo.ownedMatchSlug = ownedMatchSlug
    userInfo.allTracksPlaylistSlug = allTracksPlaylistSlug
  }
  realtime.endpoint(`user/${username}/info`).set(userInfo)
  const playlistMap = playlistInfo ? { [allTracksPlaylistSlug]: playlistInfo } : {}
  dispatch(
    blasterPeersSlice.actions.initCurrentUser({
      username,
      ownedMatchSlug,
      allTracksPlaylistSlug,
      playlistMap,
    })
  )
})

// TODO: still useful?
const toggleLeaderboard = createAsyncThunk(
  'toggleLeaderboard',
  (
    { matchOwner, matchSlug, isWatch }: { matchOwner: string; matchSlug: string; isWatch: boolean },
    { dispatch, getState }
  ) => {
    const path = `matches/${matchOwner}/${matchSlug}/leaderboard`

    if (isWatch) {
      realtime.endpoint(path).on('value', (snapshot) => {
        const leaderboard = snapshot.val() || []
        dispatch(leaderboardSlice.actions.updateLeaderboard({ matchOwner, matchSlug, leaderboard }))
      })
    } else {
      realtime.endpoint(path).off('value')
    }
  }
)

export type MinimalPlaylistUpdateInfo = {
  slug: string
  title: string
  trackOrder: string[]
}
type UpdateMatchInfoPayload = {
  matchInfo: MatchInfo
  playlistInfo?: MinimalPlaylistUpdateInfo[]
}
const addOrUpdateMatchInfo = createAsyncThunk<
  void,
  UpdateMatchInfoPayload,
  { state: RootState; dispatch: AppDispatch }
>('addOrUpdateMatchInfo', async ({ matchInfo, playlistInfo }, { dispatch, getState }) => {
  const { slug: compoundSlug } = matchInfo
  const [matchOwner, matchSlug] = compoundSlug.split('/')
  const state = getState()
  const currentUsername = selectCurrentUsername(state)
  const prevMatchInfo = selectMatchInfo(matchOwner, matchSlug)(state)
  const activeMatches = selectActiveMatches(state)
  const { playlists = {} } = prevMatchInfo
  if (matchOwner !== currentUsername) {
    throw new Error(`can't update other user's match [${matchOwner}]`)
  }
  const isNew =
    activeMatches.findIndex(
      ({ matchOwner: owner, matchSlug: slug }) => owner === matchOwner && slug === matchSlug
    ) < 0
  if (isNew) {
    const newActiveMatch = { matchOwner, matchSlug, inviteKey: '' } // no key needed for user's own matches
    const newActiveMatches = [newActiveMatch, ...activeMatches]
    const compactActiveMatches = newActiveMatches.map(Util.getCompactMatchSlug)
    realtime.endpoint(`user/${currentUsername}/matches/active`).set(compactActiveMatches)
    dispatch(sessionSlice.actions.setActiveMatches(newActiveMatches))
  }
  const updatedMatchInfo = {
    ...matchInfo,
  }
  if (playlistInfo) {
    const updatedPlaylists: PlaylistInfoMap = {}
    playlistInfo.forEach(({ slug, title, trackOrder = [] }) => {
      const updatedPlaylist = isNew ? defaultPlaylistInfo : playlists[slug] // TODO: count words etc
      updatedPlaylists[slug] = {
        ...updatedPlaylist,
        slug,
        title,
        trackOrder,
      }
    })
    updatedMatchInfo.playlists = updatedPlaylists
    updatedMatchInfo.playlistOrder = playlistInfo.map(({ slug }) => slug)
  }
  if (isNew) {
    const newMatchStatus = { ...defaultMatchStatus(), info: updatedMatchInfo }
    realtime.endpoint(`matches/${currentUsername}/${matchSlug}/`).set(newMatchStatus)
    await dispatch(
      matchStatusSlice.actions.updateMatchStatus({
        matchOwner: currentUsername,
        matchSlug,
        matchStatus: newMatchStatus,
      })
    )
    dispatch(
      addOrUpdateGuestPlayer({
        compoundSlug,
        player: {
          slug: currentUsername,
          name: currentUsername,
        },
      })
    )
    dispatch(
      matchStatusSlice.actions.updateLeaderboard({
        matchOwner: currentUsername,
        matchSlug,
        leaderboard: [`${currentUsername}/${currentUsername}`],
      })
    )
  } else {
    realtime.endpoint(`matches/${currentUsername}/${matchSlug}/info`).set(updatedMatchInfo)
    dispatch(
      matchStatusSlice.actions.updateMatchInfo({
        matchOwner: currentUsername,
        matchSlug,
        matchInfo: updatedMatchInfo,
      })
    )
  }
})

const updateUserMatchOrder = createAsyncThunk<
  void,
  UserMatchInfo[],
  { state: RootState; dispatch: AppDispatch }
>('updateUserMatchOrder', (newOrderedMatches, { dispatch, getState }) => {
  const state = getState()
  const currentUsername = selectCurrentUsername(state)
  const emptyUserMatches: UserMatchesInfo = { active: [], archived: [] }
  const partitionedMatchOrderInfo: UserMatchesInfo = newOrderedMatches.reduce((acc, matchInfo) => {
    const { matchSlug, matchOwner, isActive } = matchInfo
    const { active, archived } = acc
    const existing = (isActive ? active : archived) || []
    if (
      existing.findIndex(
        ({ matchOwner: owner, matchSlug: slug }) => owner === matchOwner && slug === matchSlug
      ) >= 0
    ) {
      return acc // TODO: just swallow? why might this happen?
    }
    const updated = [...existing, matchInfo]
    const newAcc = {
      active: isActive ? updated : active,
      archived: isActive ? archived : updated,
    }
    return newAcc
  }, emptyUserMatches)
  const compactPartitionedMatchSlugs = {
    active: (partitionedMatchOrderInfo.active || []).map(Util.getCompactMatchSlug),
    archived: partitionedMatchOrderInfo.archived.map(Util.getCompactMatchSlug),
  }
  realtime.endpoint(`user/${currentUsername}/matches`).set(compactPartitionedMatchSlugs)
  dispatch(sessionSlice.actions.setMatches(partitionedMatchOrderInfo))
})

type ConfirmationMap = {
  [username: string]: boolean
}
const confirmUsersExist = createAsyncThunk<
  Promise<any>,
  string[],
  { state: RootState; dispatch: AppDispatch }
>('confirmUsersExist', (usernameList = [], { dispatch, getState }) => {
  const usernamePromises = usernameList.map((username) => {
    return realtime.endpointOnce(`user/${username}/info/status`).then((status) => {
      return !!status
    })
  })
  return Promise.all(usernamePromises).then((confirmationResults) => {
    const confirmationMap: ConfirmationMap = confirmationResults.reduce((acc, exists, index) => {
      const username = usernameList[index]
      return { ...acc, [username]: exists }
    }, {})
    return confirmationMap
  })
})

export const joinMatch = createAsyncThunk<
  GetMatchInviteResult,
  InvitePath,
  { state: RootState; dispatch: AppDispatch }
>('joinMatch', async (args, { dispatch, getState }) => {
  const { matchOwner, matchSlug, inviteKey } = args
  const state = getState()
  const currentUsername = selectCurrentUsername(state)
  const payload = await dispatch(getMatchInvite({ matchOwner, matchSlug, inviteKey })).unwrap()
  const { isJoinable } = payload
  if (isJoinable) {
    const newActiveMatch = { matchOwner, matchSlug, inviteKey }
    const activeMatches = selectActiveMatches(state)
    const newActiveMatches = [newActiveMatch, ...activeMatches]
    const compactActiveMatches = newActiveMatches.map(Util.getCompactMatchSlug)
    realtime.endpoint(`user/${currentUsername}/matches/active`).set(compactActiveMatches)
    dispatch(sessionSlice.actions.setActiveMatches(newActiveMatches))
    dispatch(loadMatch({ matchOwner, matchSlug, isLoadFirstTrack: true })).then(() => {
      dispatch(matchSwitchedTo({ matchOwner, matchSlug }))
    })
  }
  return payload
})
type GetMatchInviteResult = {
  isJoinable: boolean
  reason?: string
  inviteInfo?: InviteInfo
}
export const getMatchInvite = createAsyncThunk<
  GetMatchInviteResult,
  InvitePath,
  { state: RootState; dispatch: AppDispatch }
>('getMatchInvite', async ({ matchOwner, matchSlug, inviteKey }, { getState }) => {
  const activeMatches = selectActiveMatches(getState())
  const isAlreadyActive =
    activeMatches.findIndex(
      ({ matchOwner: owner, matchSlug: slug }) => owner === matchOwner && slug === matchSlug
    ) >= 0
  if (isAlreadyActive) {
    return { isJoinable: false, reason: 'match already joined' }
  }
  const inviteInfo: InviteInfo = await realtime.endpointOnce(
    `matches/${matchOwner}/${matchSlug}/invites/${inviteKey}`
  )
  if (!inviteInfo) {
    return { isJoinable: false, reason: 'invitation not found' }
  }
  const { expires } = inviteInfo
  if (expires <= Date.now()) {
    return { isJoinable: false, reason: 'invitation expired' }
  }
  const title = await realtime.endpointOnce(`matches/${matchOwner}/${matchSlug}/info/title`)
  const fullMatchInfo: InviteInfo = { ...inviteInfo, matchTitle: title }
  return { isJoinable: true, inviteInfo: fullMatchInfo }
})

const createOrUpdateMatchInvite = createAsyncThunk<
  void,
  { matchSlug: string; inviteInfo: MinimalInviteInfo },
  { state: RootState }
>('createOrUpdateMatchInvite', ({ matchSlug, inviteInfo }, { dispatch, getState }) => {
  const state = getState()
  const currentUsername = selectCurrentUsername(state)
  const { slug } = inviteInfo
  realtime.set(`matches/${currentUsername}/${matchSlug}/invites/${slug}`, inviteInfo)
  dispatch(
    matchStatusSlice.actions.addOrUpdateMatchInvite({
      matchOwner: currentUsername,
      matchSlug,
      matchInvite: inviteInfo,
    })
  )
})

const deleteMatchInvite = createAsyncThunk<
  void,
  { matchSlug: string; inviteSlug: string },
  { state: RootState; dispatch: AppDispatch }
>('deleteMatchInvite', ({ matchSlug, inviteSlug }, { dispatch, getState }) => {
  const state = getState()
  const matchOwner = selectCurrentUsername(state) // can only delete own invites
  realtime.set(`matches/${matchOwner}/${matchSlug}/invites/${inviteSlug}`, null)
  dispatch(matchStatusSlice.actions.removeMatchInvite({ matchOwner, matchSlug, inviteSlug }))
})

const inviteUsersToMatch = createAsyncThunk<
  void,
  { matchOwner: string; matchSlug: string; inviteInfo: any; invitees: string[] },
  { state: RootState; dispatch: AppDispatch }
>(
  'inviteUserToMatch',
  ({ matchOwner, matchSlug, inviteInfo, invitees }, { dispatch, getState }) => {
    const state = getState()
    const currentUsername = selectCurrentUsername(state)
    const { title: matchTitle } = selectMatchInfo(matchOwner, matchSlug)(state)
    const { slug: inviteKey } = inviteInfo
    const invitation = { matchOwner, matchSlug, matchTitle, ...inviteInfo }
    // TODO: tweak confirmUsersExist so it can more easily be used here
    invitees.forEach((invitee) => {
      realtime.set(`user/${invitee}/invitesFrom/${currentUsername}/${inviteKey}`, invitation)
    })
  }
)

type MatchInvitationResponseArgs = {
  matchOwner: string
  inviteOwner: string
  matchSlug: string
  inviteKey: string
  isAccept: boolean
}
const matchInvitationResponse = createAsyncThunk<
  void,
  MatchInvitationResponseArgs,
  { state: RootState; dispatch: AppDispatch }
>(
  'matchInvitationResponse',
  (
    { inviteOwner, matchOwner, matchSlug, inviteKey, isAccept }: MatchInvitationResponseArgs,
    { dispatch, getState }
  ) => {
    const state = getState()
    const currentUsername = selectCurrentUsername(state)
    if (isAccept) {
      dispatch(joinMatch({ matchOwner, matchSlug, inviteKey })).then(() => {
        // TODO: handle failure, or just allow to fall through and delete failing invite?
      })
    }
    realtime.set(`user/${currentUsername}/invitesFrom/${inviteOwner}/${inviteKey}`, null)
    // clear the invite (no need to dispatch, cuz we're listening to this endpoint)
  }
)

const resyncOwnedMatchStatus = createAsyncThunk<void, void, { state: RootState }>(
  'resyncOwnedMatchStatus',
  (_, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      const state = getState()
      const compoundMatchSlug = selectOwnedMatchSlug(state)
      const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
      const matchStatuses = selectOwnerMatchStatuses(matchOwner)(state)
      const playlistMap = selectOwnedPlaylistMap(matchOwner)(state)
      const trackMap = selectUserTracksInfo(matchOwner)(state)
      const trackToPlaylistsMap = selectTrackToPlaylistsMap(matchOwner)(state)
      const ownerMatchScore = computeOwnerMatchScore({
        matchSlug,
        matchStatuses,
        playlistMap,
        trackMap,
        trackToPlaylistsMap,
      })
      // TODO: timestamps?
      realtime.set(
        `matches/${matchOwner}/${matchSlug}/players2/${matchOwner}${matchOwner}`,
        ownerMatchScore,
        (error) => {
          if (error) {
            console.log(error)
          }
          resolve(error)
        }
      )
    })
  }
)

const updateMatchStatus = createAsyncThunk<void, number, { state: RootState }>(
  'updateMatchStatus',
  (gamerIndex = -1, { getState }) => {
    const state = getState()
    const { username, currentMatchSlug: compoundMatchSlug, mode } = selectCurrentSessionInfo(state)
    const isCurrentOwnedMatch = selectIsCurrentOwnedMatch(state)
    if (isCurrentOwnedMatch && mode === 'play') {
      return
    }
    const currentGamers = selectCurrentGamers(state)
    const gamersToUpdate =
      gamerIndex >= 0
        ? [currentGamers[gamerIndex]]
        : currentGamers.filter((gamerInfo) => {
            return gamerInfo.isActive
          })
    gamersToUpdate.forEach((gamerInfo, index) => {
      const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
      const playlistSlug = selectCurrentPlaylistSlug(state)
      const { gamerId, playStatus } = gamerInfo
      const { song: currTrackSlug } = playStatus

      const playerScore = selectPlayerScore({
        username,
        player: gamerId,
        matchOwner,
        matchSlug,
      })(state)
      const { matchScore, playlistScores, trackScores } = playerScore
      const currTrackScore = trackScores[currTrackSlug] || playStatus
      const { [playlistSlug]: playlistScore = defaultMatchScore() } = playlistScores
      const matchPath = `matches/${compoundMatchSlug}/players2/${username}/${gamerId}`
      realtime.set(`${matchPath}/matchScore`, matchScore)
      realtime.set(`${matchPath}/playlistScores/${playlistSlug}`, playlistScore)
      realtime.set(`${matchPath}/trackScores/${currTrackSlug}`, currTrackScore)
    })
  }
)

const switchActiveBlaster = createAsyncThunk<
  void,
  { gamerIndex: number; newBlasterSlug: string; newlyLoadedTrackInfo?: TrackInfo },
  { state: RootState }
>(
  'switchActiveBlaster',
  ({ gamerIndex, newBlasterSlug, newlyLoadedTrackInfo }, { dispatch, getState }) => {
    const state = getState()
    const { currentMatchSlug: compoundMatchSlug, username } = selectCurrentSessionInfo(state)
    const currentTrackInfo = selectCurrentTrackInfo(state)
    const trackInfo = newlyLoadedTrackInfo || currentTrackInfo
    const trackSlug = trackInfo ? trackInfo.slug : selectCurrentTrackSlug(state)
    const numElements = trackInfo?.wordCount || 0
    const trackDuration = trackInfo?.duration || 0.0
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    const playerScore = selectPlayerScore({
      username,
      player: newBlasterSlug,
      matchOwner,
      matchSlug,
    })(state)
    const { trackScores } = playerScore
    const currTrackScore = trackScores[trackSlug] || defaultMatchScore() // TODO: getFrom gamer
    const maxScore = numElements * getTrackPoints(numElements, trackDuration)
    trackMixer.clearTimingForGamer(gamerIndex)
    dispatch(currentPlaySlice.actions.setGamerId({ gamerIndex, gamerId: newBlasterSlug }))
    dispatch(
      currentPlaySlice.actions.setPlayStatus({
        gamerIndex,
        playStatus: {
          ...currTrackScore,
          song: trackSlug,
          trackDuration,
          maxScore,
          numElements,
        },
      })
    )
    // if (compoundMatchSlug && playlistSlug && trackSlug) {
    //   dispatch(
    //     socialSlice.actions.toggleUserExpanded({
    //       isLeft: true,
    //       username: currentBlaster,
    //       matchSlug: compoundMatchSlug,
    //       isExpanded: false,
    //     })
    //   )
    //   dispatch(
    //     socialSlice.actions.clearActiveTrack({
    //       isLeft: true,
    //       username: currentBlaster,
    //       matchSlug: compoundMatchSlug,
    //       playlistSlug,
    //       trackSlug,
    //     })
    //   )
    //   dispatch(
    //     socialSlice.actions.revealTrack({
    //       isLeft: true,
    //       username: newBlasterSlug,
    //       matchSlug: compoundMatchSlug,
    //       playlistSlug,
    //       trackSlug,
    //       owner: '',
    //     })
    //   )
    // }
  }
)

const addOrUpdateGuestPlayer = createAsyncThunk<
  void,
  { compoundSlug: string; player: GuestPlayer },
  { state: RootState; dispatch: AppDispatch }
>('addOrUpdateGuestPlayer', ({ player, compoundSlug }, { dispatch, getState }) => {
  const state = getState()
  const currentUsername = selectCurrentUsername(state)
  const [matchOwner, matchSlug] = compoundSlug.split('/')
  const { info: matchInfo, leaderboard } = selectMatchStatus(matchOwner, matchSlug)(state)
  const { guestOrder = [] } = matchInfo
  const compoundPlayerSlug = `${currentUsername}/${player.slug}`
  const isNew = !guestOrder.includes(player.slug)
  if (isNew) {
    const newLeaderboard = leaderboard.concat(compoundPlayerSlug)
    const newGuestOrder = guestOrder.concat(player.slug)
    const newMatchInfo = {
      ...matchInfo,
      guestOrder: newGuestOrder,
    }
    dispatch(
      matchStatusSlice.actions.updateMatchInfo({ matchOwner, matchSlug, matchInfo: newMatchInfo })
    )
    dispatch(
      matchStatusSlice.actions.updateLeaderboard({
        matchOwner,
        matchSlug,
        leaderboard: newLeaderboard,
      })
    )
    realtime.endpoint(`matches/${compoundSlug}/info/guestOrder`).set(newGuestOrder)
    realtime.endpoint(`matches/${compoundSlug}/leaderboard`).set(newLeaderboard)
  }
  dispatch(matchStatusSlice.actions.updateMatchPlayerName({ matchOwner, matchSlug, player }))
  realtime
    .endpoint(`matches/${compoundSlug}/players2/${compoundPlayerSlug}`)
    .set({ name: player.name })
})

export {
  addOrUpdateMatchInfo,
  confirmUsersExist,
  deleteMatchInvite,
  initOrUpdateCurrentUser,
  createOrUpdateMatchInvite,
  inviteUsersToMatch,
  matchInvitationResponse,
  resyncOwnedMatchStatus,
  switchActiveBlaster,
  toggleLeaderboard,
  addOrUpdateGuestPlayer,
  updateMatchStatus,
  updateUserMatchOrder,
}
