import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import themes from '../components/themes'
import { AudienceType, InvitesFromMap, ModeType, UserMatchesInfo, VizType } from '../types'

export const currentPrefsVersion = 6

export interface SessionState {
  isInitialized: boolean
  isRealtimeInitialized: boolean
  trackError: string
  matches: UserMatchesInfo
  matchInvites: InvitesFromMap
  username: string
  prefs: {
    version: number
    quickStart: {
      isShowOnStartup: boolean
    }
    termsOfUse: {
      userHasAgreed: boolean
    }
    theme: number
    audience: AudienceType
    defaultMode: ModeType | 'sticky'
    latencyMillis: number
    isUseLatency: boolean
    lastTrackUri: string
  }
  currentTrackPath: string
  currentMatchSlug: string
  publicPlaylists: []
  isMulti: boolean
  mode: ModeType
  isShowCountdownClock: boolean
  visualizationInfo: {
    defaultVizSlug: VizType
    currentVizSlug: VizType
    alternateVizSlug: VizType
  }[]
  isShowSocial: boolean
  isShowGameSidebar: boolean
  isShowSettings: boolean
  userAliases: string[]
  currUserAliasIndex: number
  spotifyToken: string
  spotifyPlayerState: null
  currentBackgroundIndex: number
  currentThemeIndex: number
  currentBackgroundBlendMode: number
  isFullscreen: boolean
  playerVisibility: {
    isShowGuestPlayers: boolean
    isShowUserPlayers: boolean
    isShowHostPlayer: boolean
  }
}

const initialState: SessionState = {
  isInitialized: false,
  isRealtimeInitialized: false,
  trackError: '',
  matches: { active: null, archived: [] },
  matchInvites: {},
  username: '',
  prefs: {
    version: currentPrefsVersion,
    quickStart: {
      isShowOnStartup: true,
    },
    termsOfUse: {
      userHasAgreed: false,
    },
    theme: 0,
    audience: 'solo',
    defaultMode: 'viz',
    latencyMillis: 0,
    isUseLatency: true,
    lastTrackUri: '',
  },
  currentTrackPath: '',
  currentMatchSlug: '',
  publicPlaylists: [],
  isShowCountdownClock: false,
  isMulti: false,
  mode: 'play',
  visualizationInfo: [
    { defaultVizSlug: 'page', alternateVizSlug: 'spiral', currentVizSlug: 'page' },
    { defaultVizSlug: 'page', alternateVizSlug: 'spiral', currentVizSlug: 'page' },
  ],
  isShowSocial: false,
  isShowGameSidebar: true,
  isShowSettings: false,
  userAliases: [],
  currUserAliasIndex: 0,
  spotifyToken: '',
  spotifyPlayerState: null,
  currentBackgroundIndex: 0,
  currentBackgroundBlendMode: 0,
  currentThemeIndex: 0,
  isFullscreen: false,
  playerVisibility: {
    isShowGuestPlayers: true,
    isShowUserPlayers: false,
    isShowHostPlayer: false,
  },
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    initSession: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setPrefs: (state, action) => {
      return {
        ...state,
        prefs: action.payload,
      }
    },
    setCurrentTrackPath: (state, action) => {
      state.currentTrackPath = action.payload
    },
    setTrackError: (state, action) => {
      state.trackError = action.payload
    },
    setCurrentBackgroundIndex: (state, action) => {
      state.currentBackgroundIndex = action.payload
    },
    setCurrentThemeIndex: (state, action: PayloadAction<number>) => {
      const newThemeIndex = action.payload
      if (0 <= newThemeIndex && newThemeIndex < themes.length) {
        state.currentThemeIndex = newThemeIndex
      } else {
        console.log(`ignoring bad theme index: [${newThemeIndex}]`)
      }
    },
    setCurrentThemeIndexByName: (state, action: PayloadAction<string>) => {
      const newThemeIndex = themes.findIndex((theme) => {
        return theme.name === action.payload
      })
      if (newThemeIndex < 0) {
        console.log(`[${action.payload}] not found in themes`)
      } else {
        state.currentThemeIndex = newThemeIndex
      }
    },
    setCurrentBackgroundBlendMode: (state, action) => {
      state.currentBackgroundBlendMode = action.payload
    },
    setCurrentVisualizationSlug: (
      state,
      action: PayloadAction<{ playerIndex: number; vizSlug: VizType }>
    ) => {
      const { playerIndex, vizSlug } = action.payload
      state.visualizationInfo[playerIndex].currentVizSlug = vizSlug
    },
    setDefaultVisualizationSlug: (
      state,
      action: PayloadAction<{ playerIndex: number; vizSlug: VizType }>
    ) => {
      const { playerIndex, vizSlug } = action.payload
      state.visualizationInfo[playerIndex].defaultVizSlug = vizSlug
    },
    setCurrentMatchSlug: (state, action) => {
      state.currentMatchSlug = action.payload
    },
    setMode: (state, action: PayloadAction<ModeType>) => {
      state.mode = action.payload
    },
    setIsShowCountdownClock: (state, action: PayloadAction<boolean>) => {
      state.isShowCountdownClock = action.payload
    },
    setIsMulti: (state, action: PayloadAction<boolean>) => {
      state.isMulti = action.payload
    },
    setMatches: (state, action) => {
      state.matches = action.payload
    },
    setActiveMatches: (state, action) => {
      state.matches.active = action.payload
    },
    setMatchInvites: (state, action) => {
      state.matchInvites = action.payload
    },
    setIsShowSocial: (state, action) => {
      state.isShowSocial = action.payload
    },
    setIsShowGameSidebar: (state, action) => {
      state.isShowGameSidebar = action.payload
    },
    setIsShowSettings: (state, action) => {
      state.isShowSettings = action.payload
    },
    setSpotifyToken: (state, action) => {
      state.spotifyToken = action.payload || ''
    },
    setSpotifyPlayerState: (state, action) => {
      state.spotifyPlayerState = action.payload
    },
    setIsFullscreen: (state, action) => {
      state.isFullscreen = action.payload
    },
    setPlayerVisibility: (state, action) => {
      state.playerVisibility = action.payload
    },
  },
})

export default sessionSlice
