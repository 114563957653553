import { createAsyncThunk } from '@reduxjs/toolkit'

import { PLAYLIST_TYPES } from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import sessionSlice from '../../reducers/sessionSlice'
import { selectBlasterPeer } from '../../selectors/blaster-peer-selectors'
import { PlaylistInfo } from '../../types'
import { selectCurrentMatchSlug } from '../../selectors/session-selectors'
import { matchSwitchedTo } from '../load-match'

const setCurrentPlaylist = createAsyncThunk<
  void,
  { matchSlug: string; playlistInfo: PlaylistInfo },
  { state: RootState; dispatch: AppDispatch }
>(
  'setCurrentPlaylist',
  ({ matchSlug: compoundMatchSlug, playlistInfo }, { dispatch, getState }) => {
    const { owner } = playlistInfo
    const state = getState()
    const [matchOwner] = compoundMatchSlug.split('/')
    const blasterPeer = selectBlasterPeer(matchOwner)(state)
    const currentMatchSlug = selectCurrentMatchSlug(state)
    if (currentMatchSlug !== compoundMatchSlug) {
      const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
      dispatch(matchSwitchedTo({ matchOwner, matchSlug }))
      // // console.log('new matchSlug', compoundMatchSlug)
      // const matchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
      // console.log('new matchSlug', compoundMatchSlug, matchStatus.info.guestOrder)
    }
    const { isCurrentUser } = blasterPeer
    const prefix = isCurrentUser ? PLAYLIST_TYPES.OWNER : owner
    dispatch(sessionSlice.actions.setCurrentMatchSlug(compoundMatchSlug))
    dispatch(
      currentPlaySlice.actions.setPlaylistInfo({
        ...playlistInfo,
        prefix,
      })
    )
  }
)

export default setCurrentPlaylist
