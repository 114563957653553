import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../reducers'
import sessionSlice from '../reducers/sessionSlice'
import settingsSlice from '../reducers/settingsSlice'
import player from '../services/TrackMixer'
import { ModeType } from '../types'
import { selectCurrentGamers, selectCurrentLRC } from '../selectors/current-play-selectors'
import currentPlaySlice from '../reducers/currentPlaySlice'

const switchUserMode = createAsyncThunk<
  void,
  ModeType,
  { state: RootState; dispatch: AppDispatch }
>('playAction/switchUserMode', (newMode, { dispatch, getState }) => {
  return new Promise<void>((resolve, reject) => {
    const referenceLrcLines = selectCurrentLRC(getState()) || []
    dispatch(sessionSlice.actions.setMode(newMode))
    dispatch(settingsSlice.actions.setIsShowLyrics({ isShowLyrics: true }))
    dispatch(settingsSlice.actions.setIsVisualPulse({ isVisualPulse: newMode === 'viz' }))
    dispatch(settingsSlice.actions.setIsAudioPulse({ isAudioPulse: false }))
    dispatch(sessionSlice.actions.setIsShowSettings(false))
    player.switchMode(newMode, referenceLrcLines)
    resolve()
  })
})
export const toggleGamer = createAsyncThunk<
  void,
  number,
  { state: RootState; dispatch: AppDispatch }
>('playAction/switchUserMode', (gamerIndex, { dispatch, getState }) => {
  const state = getState()
  const currGamers = selectCurrentGamers(state)
  const currLyricsTimingText = selectCurrentLRC(state) || []

  return new Promise<void>((resolve, reject) => {
    if (gamerIndex >= currGamers.length) {
      console.log('bad gamer index', gamerIndex)
    } else {
      const { isActive } = currGamers[gamerIndex]
      const isBecomingActive = !isActive
      dispatch(currentPlaySlice.actions.toggleGamerActive(gamerIndex))
      player.toggleGamerActive(gamerIndex, isBecomingActive, currLyricsTimingText)
    }
    resolve()
  })
})

export default switchUserMode
