import {
  DEFAULT_SLUG,
  defaultMatchScore,
  defaultPlayStatusTrackInfo,
  defaultScoreCounters,
  defaultTrackInfo,
  rankEnum,
} from '../constants/constants'
import Util from './util'
import {
  Line,
  MatchStatusMap,
  PlayerStatus,
  PlayerStatusMap,
  PlaylistInfo,
  PlaylistInfoMap,
  PlayStatus,
  PlayStatusMap,
  PlayStatusScoreInfo,
  Score,
  ScoreCountersMap,
  ScoreDelta,
  ScoreRank,
  Section,
  TrackInfo,
  TrackInfoMap,
  TrackToPlaylistsMap,
  UserMatchStatusMap,
  Word,
} from '../types'

const getTrackPoints = (wordCount: number, duration: number) => {
  return duration > 0 ? Math.floor((10 * wordCount) / duration) : 0
}

const rankVariantClasses = (variant: string) =>
  rankEnum.map(function (name) {
    return `${name}-${variant}`
  })

const rankClassLists = rankEnum
  .concat(rankVariantClasses('ahead'))
  .concat(rankVariantClasses('behind'))

const lerp = (from: number, to: number, ratio: number) => {
  if (from === to) {
    throw new Error('Invalid lerp range')
  }
  if (ratio === 0) {
    return from
  }
  if (ratio === 1) {
    return to
  }
  return from + (to - from) * ratio
}

const invLerp = (from: number, to: number, value: number) => {
  if (from === to) {
    throw new Error('Invalid inverse lerp range')
  }
  if (value === from) {
    return 0
  }
  if (value === to) {
    return 1
  }
  return (value - from) / (to - from)
}

const mapToScore = (
  value: number,
  fromMin: number,
  fromMax: number,
  toMin: number,
  toMax: number
) => {
  const interpolation = invLerp(fromMin, fromMax, value)
  const result = lerp(toMin, toMax, interpolation)
  return result
}

const calcWordScore = (word: Word, latencySeconds: number, trackPoints: number): Score => {
  const { time, referenceTime } = word
  if (!referenceTime || referenceTime < 0) {
    throw new Error('calcWordScore called for word with no reference time')
  }
  // const marginError = .05;
  const deltaTime = (time || 0) - latencySeconds - referenceTime
  const aheadOrBehind = deltaTime > 0 ? 'ahead' : 'behind'
  const absDeltaTime = Math.abs(deltaTime)
  let rank: ScoreRank = 'perfect'
  let rankClass = [rank as string]
  let effectiveDeltaTime = 0
  let margin = 'far'
  if (absDeltaTime > 0.1) {
    if (absDeltaTime < 0.3) {
      rank = 'excellent'
      effectiveDeltaTime = 0.1
      if (absDeltaTime < 0.2) {
        margin = 'close'
      }
    } else if (absDeltaTime < 0.6) {
      rank = 'good'
      effectiveDeltaTime = 0.3
      if (absDeltaTime < 0.5) {
        margin = 'close'
      }
    } else {
      rank = 'bad'
      effectiveDeltaTime = 0.6
      if (absDeltaTime < 0.7) {
        margin = 'close'
      }
    }
    rankClass = [rank, aheadOrBehind, margin]
  }

  const interpolatedPercent = Math.max(mapToScore(effectiveDeltaTime, 0, 0.6, 10, 0), 0)
  const trackWeightedScore = (interpolatedPercent * trackPoints) / 10
  const score = Math.max(1, Math.floor(trackWeightedScore))
  // console.log(trackPoints, rank, interpolatedPercent, trackWeightedScore, score)
  return {
    deltaTime,
    value: score,
    rank,
    rankClass,
  }
}

const updateWordOnBlast = (
  word: Word,
  isEditMode: boolean,
  latencySeconds: number,
  trackPoints: number,
  isRight: boolean
): ScoreDelta | undefined => {
  const { elem } = word
  elem?.classList.remove('masked')
  rankClassLists.forEach(function (name) {
    elem?.classList.remove(name)
  })
  if (isEditMode) {
    elem?.classList.remove('scored')
    return
  }
  const wordScore = calcWordScore(word, latencySeconds, trackPoints)
  const { rankClass, value, rank } = wordScore
  if (elem) {
    const classes = rankClass.concat('scored')
    classes.forEach((className) => {
      elem.classList.add(className)
    })
  }

  const { score: currentWordScore } = word
  const { value: currScoreValue, rank: currScoreRank } = currentWordScore || {}
  word.score = wordScore

  return {
    oldValue: currScoreValue,
    newValue: value,
    oldRank: currScoreRank,
    newRank: rank,
    isRight,
  }
}

const clearWordScoringClasses = (word: Word) => {
  rankClassLists.forEach(function (name) {
    word.elem?.classList.remove(name)
  })
  word.elem?.classList.remove('scored', 'error')
}

const rankBreakpoints = [0.9, 0.7, 0.4]

const getScoreRank = (score: number, maxScore: number) => {
  let rank: ScoreRank = 'perfect'

  if (maxScore) {
    const percentage = score / maxScore

    if (percentage < rankBreakpoints[0]) {
      if (percentage > rankBreakpoints[1]) {
        rank = 'excellent'
      } else if (percentage > rankBreakpoints[2]) {
        rank = 'good'
      } else {
        rank = 'bad'
      }
    }
  }
  return rank
}

// const getScoreBreakpoints = (maxScore) => {
//   const percentages = [0, .4, .7, .9];
//   const breakpoints = percentages.map(function(percentage) {
//     return maxScore * percentage;
//   });
//   return breakpoints;
// };

// TODO: revisit lap proration/penalty. Incremental vs. overall calculations are not compatible
// E.g. maybe store pure score alongside penalties (and eventually bonuses), and plus computed, final score

const lapProrationFactor = 0.01 // percentage to devalue points per additional lap

// TODO: weight score by numElements, as well as other factors, e.g. tempo
const incrementalRecalc = (
  currPlayStatus: PlayStatus,
  currentLapCount: number,
  wordScoreDelta: ScoreDelta
) => {
  const {
    topScore: currentTopScore,
    maxScore,
    counters: currentCounters,
    score: currentScore,
    doneCount: currentDoneCount,
  } = currPlayStatus
  const { oldValue = 0, newValue, oldRank, newRank } = wordScoreDelta
  const lapProration = 1 - lapProrationFactor * currentLapCount
  const scoreDelta = (newValue - oldValue) * lapProration
  const newScore = Math.max(Math.round(currentScore + scoreDelta), 0)
  const isNewHighScore = newScore > currentTopScore
  const topScore = isNewHighScore ? newScore : currentTopScore
  const topScoreRank = getScoreRank(topScore, maxScore)
  const doneCount = currentDoneCount + (oldRank ? 0 : 1) // TODO: support clearing
  const counters = { ...currentCounters }
  if (oldRank !== newRank) {
    if (oldRank) {
      counters[oldRank] = counters[oldRank] - 1
    }
    if (newRank) {
      counters[newRank] = counters[newRank] + 1
    }
  }
  return {
    ...currPlayStatus,
    score: newScore,
    isNewHighScore,
    topScore,
    topScoreRank,
    counters,
    doneCount,
  }
}

const getPlaylistScore = (playlistInfo: PlaylistInfo, trackScores: PlayStatusMap) => {
  const { trackOrder = [], slug: playlistSlug } = playlistInfo
  const initialPlaylistScore: PlayStatus = {
    ...defaultMatchScore(),
    song: playlistSlug, // TODO: rename to slug
  }
  const playlistScore = trackOrder.reduce((playlistAcc, slug) => {
    const trackScore = trackScores[slug]
    if (!trackScore) {
      return playlistAcc
    }
    const {
      topScore,
      scoreSeconds,
      topScoreRank: trackRank,
      doneCount,
      maxScore,
      numElements,
      // trackDuration,
    } = trackScore
    const counters = { ...playlistAcc.counters }
    counters[trackRank || 'bad']++ // TODO: better way to guarantee
    const playlistTopScore = playlistAcc.topScore + topScore
    const playlistMaxScore = playlistAcc.maxScore + maxScore
    const topScoreRank = getScoreRank(playlistTopScore, playlistMaxScore)
    return {
      ...playlistAcc,
      scoreSeconds: playlistAcc.scoreSeconds + scoreSeconds,
      doneCount: playlistAcc.doneCount + doneCount,
      counters,
      numLaps: 0, // TODO
      numElements: playlistAcc.numElements + numElements,
      // trackDuration: playlistAcc.trackDuration + trackDuration,
      maxScore: playlistMaxScore,
      topScore: playlistTopScore,
      score: playlistTopScore, // no diff between topScore and score for playlists
      topScoreRank,
    }
  }, initialPlaylistScore)

  return playlistScore
}

const getMatchScore = (currMatchStatus: PlayerStatus) => {
  const { matchScore: currMatchScore = defaultMatchScore(), playlistScores = {} } = currMatchStatus
  const initialMatchScore: PlayStatus = {
    ...defaultMatchScore(),
    song: currMatchScore.song, // TODO: rename to slug
  }
  const matchScore = Object.keys(playlistScores).reduce((matchAcc, slug) => {
    const playlistScore = playlistScores[slug]
    if (!playlistScore) {
      return matchAcc
    }
    const {
      topScore,
      scoreSeconds,
      topScoreRank: playlistRank,
      doneCount,
      maxScore,
      numElements,
      // trackDuration,
    } = playlistScore
    const counters = { ...matchAcc.counters }
    counters[playlistRank || 'bad']++ // TODO: better way to guarantee
    const matchTopScore = matchAcc.topScore + topScore
    const matchMaxScore = matchAcc.maxScore + maxScore
    const topScoreRank = getScoreRank(matchTopScore, matchMaxScore)
    return {
      ...matchAcc,
      scoreSeconds: matchAcc.scoreSeconds + scoreSeconds,
      doneCount: matchAcc.doneCount + doneCount,
      counters,
      numLaps: 0, // TODO
      numElements: matchAcc.numElements + numElements,
      // trackDuration: matchAcc.trackDuration + trackDuration,
      maxScore: matchMaxScore,
      topScore: matchTopScore,
      score: matchTopScore, // no diff between topScore and score for for matches
      topScoreRank,
    }
  }, initialMatchScore)

  return matchScore
}

const getMatchPlayerNamesByRank = (playerScores: UserMatchStatusMap = {}) => {
  const compoundPlayerIdMatchStatusMap: PlayerStatusMap = {}
  Object.keys(playerScores).forEach((username) => {
    const playerStatusMap = playerScores[username]
    Object.keys(playerStatusMap).forEach((playerName) => {
      const compoundPlayerName = `${username}/${playerName}`
      compoundPlayerIdMatchStatusMap[compoundPlayerName] = playerStatusMap[playerName]
    })
  })
  const sortedPlayerIds = Object.keys(compoundPlayerIdMatchStatusMap).sort((playerA, playerB) => {
    const {
      matchScore: { score: matchScoreA },
    } = compoundPlayerIdMatchStatusMap[playerA]
    const {
      matchScore: { score: matchScoreB },
    } = compoundPlayerIdMatchStatusMap[playerB]
    return matchScoreB - matchScoreA
  })
  return sortedPlayerIds
}

const lapPenaltyFactor = 50 // points to dock per additional lap

const recalc = ({
  gamerId,
  sections,
  currentTopScore,
  maxScore,
  currentLapCount,
  isUseDoneCount,
}: {
  gamerId: string
  sections: Section[]
  currentTopScore: number
  maxScore: number
  currentLapCount: number
  isUseDoneCount: boolean
}): PlayStatusScoreInfo => {
  const result: PlayStatusScoreInfo = {
    gamerId,
    score: 0,
    errorCount: 0,
    doneCount: 0,
    scoreSeconds: 0,
    topScore: currentTopScore,
    topScoreRank: getScoreRank(currentTopScore, maxScore),
    isNewHighScore: false,
    counters: defaultScoreCounters(),
  }
  let previousTime = 0

  sections.forEach((section: Section) => {
    section.lines.forEach((line: Line) => {
      line.words.forEach((word: Word) => {
        if (word.time !== null) {
          result.doneCount++

          if (word.time < previousTime) {
            // TODO: should forbid equal unless it's first word in section
            word.elem?.classList.add('error') // TODO: add to previous elem as well!
            result.errorCount++
          } else {
            word.elem?.classList.remove('error')
          }
          previousTime = word.time

          if (word.score) {
            result.score += word.score.value
            result.counters[word.score.rank]++
          }
        }
      })
    })
  })

  // TODO: weight score by numElements, as well as other factors, e.g. tempo
  const lapPenalty = Math.round(lapPenaltyFactor * currentLapCount)

  result.score = Math.max(result.score - lapPenalty, 0)

  const score = isUseDoneCount ? result.doneCount : result.score
  if (score > currentTopScore) {
    result.isNewHighScore = true
    result.topScore = score
    result.topScoreRank = getScoreRank(score, maxScore)
  }

  return result
}

const getScoreText = ({
  isAuthoring,
  doneCount,
  numElements,
  score,
  maxScore,
  errorCount,
}: {
  isAuthoring: boolean
  doneCount: number
  numElements: number
  score: number
  maxScore: number
  errorCount: number
}) => {
  const normalizedMaxScore = maxScore || numElements * 10
  const errorText = errorCount ? `/ ${errorCount}` : ''
  const scoreText = isAuthoring
    ? `${String(doneCount)} / ${String(numElements)} ${errorText}`
    : `${String(score)} / ${String(normalizedMaxScore)}`
  // const scoreText = Util.numberWithCommas(
  //   isAuthoring
  //     ? `${String(doneCount)} / ${String(numElements)} ${errorText}`
  //     : `${String(score)} / ${String(normalizedMaxScore)}`
  // )
  return scoreText
}

const getSummaryScoreText = ({
  isCurrentScoreTopScore,
  maxScore,
  topScore,
}: {
  isCurrentScoreTopScore: boolean
  maxScore: number
  topScore: number
}) => {
  const isPerfect = maxScore && maxScore === topScore
  const topScoreString = `${Util.numberWithCommas(topScore || 0)}${isPerfect ? '*' : ''}`
  const summaryText = isCurrentScoreTopScore ? `${topScoreString}!` : topScoreString
  return summaryText
}

const getHeaderScoreText = ({
  isCurrentScoreTopScore,
  maxScore,
  score,
}: {
  isCurrentScoreTopScore: boolean
  maxScore: number
  score: number
}) => {
  const scoreText = `${Util.numberWithCommas(score || 0)}${isCurrentScoreTopScore ? '!' : ''}`
  const headerScoreText = `${scoreText} / ${Util.numberWithCommas(maxScore)}`
  return headerScoreText
}

const computeOwnerMatchScore = ({
  matchSlug = 'default',
  matchStatuses = {},
  playlistMap = {},
  trackMap,
  trackToPlaylistsMap,
}: {
  matchSlug: string
  matchStatuses: MatchStatusMap
  playlistMap: PlaylistInfoMap
  trackMap: TrackInfoMap
  trackToPlaylistsMap: TrackToPlaylistsMap
}) => {
  const playlistScores: PlayStatusMap = {}
  const trackScores: PlayStatusMap = {}
  const matchScore = Object.keys(playlistMap).reduce((matchAcc, playlistSlug) => {
    const playlist = playlistMap[playlistSlug]
    const { trackOrder = [] } = playlist
    const playlistScore = trackOrder.reduce((playlistAcc, trackSlug) => {
      const track = trackMap[trackSlug]
      const { wordCount = 0, timedWordCount = 0, duration = 0 } = track || defaultTrackInfo
      const trackScore: PlayStatus = defaultMatchScore(defaultPlayStatusTrackInfo(trackSlug))

      trackScore.trackDuration = duration
      trackScore.doneCount = timedWordCount
      trackScore.numElements = wordCount
      trackScore.topScore = timedWordCount
      trackScore.maxScore = wordCount
      trackScore.topScoreRank = getScoreRank(timedWordCount, wordCount)
      trackScores[trackSlug] = trackScore

      const playlistDoneCount = playlistAcc.doneCount + timedWordCount
      const playlistNumElements = playlistAcc.numElements + wordCount
      playlistAcc.doneCount = playlistDoneCount
      playlistAcc.numElements = playlistNumElements
      playlistAcc.topScore = playlistDoneCount
      playlistAcc.maxScore = playlistNumElements
      playlistAcc.topScoreRank = getScoreRank(playlistDoneCount, playlistNumElements)

      return playlistAcc
    }, defaultMatchScore(defaultPlayStatusTrackInfo(playlistSlug)))

    playlistScores[playlistSlug] = playlistScore
    const { doneCount, numElements } = playlistScore
    matchAcc.doneCount += doneCount
    matchAcc.numElements += numElements
    return matchAcc
  }, defaultMatchScore(defaultPlayStatusTrackInfo(matchSlug)))

  const { aggregateTrackCounters, trackCountersByPlaylist } = computeOwnerMatchStats({
    ownedMatchSlug: matchSlug,
    matchStatuses,
    trackToPlaylistsMap,
  })
  // const matchTrackCounters = { ...defaultScoreCounters }
  Object.keys(aggregateTrackCounters).forEach((trackSlug) => {
    const counters = aggregateTrackCounters[trackSlug]
    const trackScore = trackScores[trackSlug]
    if (trackScore) {
      trackScores[trackSlug] = { ...trackScore, counters: { ...counters } }
      // rankEnum.reduce((rank) => {
      //   matchTrackCounters[rank] += counters[rank]
      // })
    }
  })
  // matchScore.counters = matchTrackCounters
  Object.keys(trackCountersByPlaylist).forEach((playlistSlug) => {
    const counters = trackCountersByPlaylist[playlistSlug]
    const playlistScore = playlistScores[playlistSlug]
    if (playlistScore) {
      playlistScores[playlistSlug] = { ...playlistScore, counters: { ...counters } }
    }
  })
  return {
    matchScore,
    playlistScores,
    trackScores,
  }
}

const computeOwnerMatchStats = ({
  matchStatuses,
  ownedMatchSlug,
  trackToPlaylistsMap,
}: {
  matchStatuses: MatchStatusMap
  ownedMatchSlug: string
  trackToPlaylistsMap: TrackToPlaylistsMap
}) => {
  // TODO: accumulate each playlist
  const publicMatchSlugs = Object.keys(matchStatuses).filter(
    (matchSlug) => matchSlug !== ownedMatchSlug
  )
  const aggregateTrackCounters: ScoreCountersMap = {}
  const trackCountersByPlaylist: ScoreCountersMap = {}
  const allTracksCounters = defaultScoreCounters()
  publicMatchSlugs.forEach((matchSlug) => {
    const { players } = matchStatuses[matchSlug]
    Object.keys(players).forEach((username) => {
      const playerStatusMap = players[username]
      Object.keys(playerStatusMap).forEach((player) => {
        const { trackScores, playlistScores } = playerStatusMap[player]
        Object.keys(trackScores).forEach((trackSlug) => {
          const { topScoreRank } = trackScores[trackSlug]
          allTracksCounters[topScoreRank]++
          const trackCounters = aggregateTrackCounters[trackSlug] || defaultScoreCounters()
          trackCounters[topScoreRank]++
          aggregateTrackCounters[trackSlug] = trackCounters
          const trackPlaylists = trackToPlaylistsMap[trackSlug]
          if (trackPlaylists) {
            Object.keys(playlistScores).forEach((playlistSlug) => {
              if (trackPlaylists.includes(playlistSlug)) {
                const trackCounters =
                  trackCountersByPlaylist[playlistSlug] || defaultScoreCounters()
                trackCounters[topScoreRank]++
                trackCountersByPlaylist[playlistSlug] = trackCounters
              }
            })
          }
        })
      })
    })
  })
  trackCountersByPlaylist[DEFAULT_SLUG] = allTracksCounters
  return { aggregateTrackCounters, trackCountersByPlaylist }
}

export const defaultTrackPlayStatus = ({
  slug,
  duration,
  timedWordCount,
}: TrackInfo): PlayStatus => {
  const maxScore = timedWordCount * getTrackPoints(timedWordCount, duration)
  return {
    gamerId: '',
    counters: defaultScoreCounters(),
    errorCount: 0,
    doneCount: 0,
    maxScore,
    numElements: timedWordCount,
    numLaps: 0,
    trackDuration: duration,
    song: slug,
    score: 0,
    scoreSeconds: 0,
    topScore: 0,
    topScoreRank: 'bad',
    isPlaying: false,
    timestamp: Date.now(),
  }
}

export {
  computeOwnerMatchScore,
  getHeaderScoreText,
  getScoreRank,
  getScoreText,
  getSummaryScoreText,
  updateWordOnBlast,
  clearWordScoringClasses,
  recalc,
  incrementalRecalc,
  getPlaylistScore,
  getMatchScore,
  getMatchPlayerNamesByRank,
  getTrackPoints,
}
